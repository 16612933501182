import React from 'react';
import { Link } from "react-router-dom";
import logo from "./../../assets/images/logo/logoi.png";

const Footer = () => {
    return (
        <footer className='footer'>
            <div>
                <div className="bg-[#343a40] text-white py-6 lg:py-10">
                    <div className="w-full px-4 lg:px-12 text-center">
                        <h2 className="text-2xl lg:text-4xl xl:text-5xl font-semibold mb-4">Ready to Get Started?</h2>
                        <p className="text-base lg:text-lg xl:text-xl mb-8">
                            Join countless other businesses that have transformed their operations with our reliable cloud hosting services.
                        </p>
                        <Link to={"/free-trial"} className="bg-button text-white py-3 px-6 rounded-lg text-lg lg:text-xl transition-colors">
                            Start Your Free Trial
                        </Link>
                    </div>
                </div>
            </div>

            

            <div className="bg-gray-900 text-white w-full px-4 lg:px-1 py-6 lg:py-10">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Company Information */}
                    <div className="mb-6 lg:mb-0">
                        <Link to={"/"}>
                            <img src={logo} className="h-12 xl:h-20 w-auto rounded-xl" alt="ConciseNext Logo" />
                        </Link>
                        <p className="text-base lg:text-lg mb-2">
                            <span className="font-bold">Address:</span> 1234 Cloud Lane, Suite 500, Tech City, TX 75001
                        </p>
                        <p className="text-base lg:text-lg mb-2">
                            <span className="font-bold">Contact:</span> support@cloudtechsolutions.com
                        </p>
                        <p className="text-base lg:text-lg mb-2">
                            <span className="font-bold">Phone:</span> +1 (123) 456-7890
                        </p>

                        <div className="mb-6 mt-5 lg:mb-0 lg:mt-10">
                        <h3 className="text-xl lg:text-2xl font-semibold mb-4">Empowering Your Cloud Journey</h3>
                        <p className="text-base lg:text-lg">
                            <span className="font-bold">Reliable Cloud Hosting:</span> Scaling your business with unparalleled performance, security, and flexibility.
                        </p>
                    </div>
                    </div>

                    

                    {/* Quick Links */}
                    <div className="mb-6 ">
                        <h3 className="text-xl lg:text-2xl font-semibold mb-4 text-center">Quick Links</h3>
                        <div className="flex flex-col lg:grid lg:grid-cols-5 gap-4 lg:w-[1200px]">
                            <ul className="space-y-2">
                                <li><Link to={"/"} className="text-gray-400 hover:text-gray-200">Home</Link></li>
                                <li><Link to={"/contact-us"} className="text-gray-400 hover:text-gray-200">Contact Us</Link></li>
                                <li><Link to={"/price"} className="text-gray-400 hover:text-gray-200">Price</Link></li>
                            </ul>
                            <ul className="space-y-2">
                                <li><Link to={"/quickbooks-hosting"} className="text-gray-400 hover:text-gray-200">QuickBooks Hosting</Link></li>
                                <li><Link to={"/quickbooks-pro-hosting"} className="text-gray-400 hover:text-gray-200">QuickBooks Pro Hosting</Link></li>
                                <li><Link to={"/quickbooks-premier-hostingt"} className="text-gray-400 hover:text-gray-200">QuickBooks Premier Hosting</Link></li>
                                <li><Link to={"/quickbooks-enterprise-hosting"} className="text-gray-400 hover:text-gray-200">QuickBooks Enterprise Hosting</Link></li>
                                <li><Link to={"/quickbooks-add-ons-hosting"} className="text-gray-400 hover:text-gray-200">QuickBooks Add-ons Hosting</Link></li>
                                <li><Link to={"/quickbooks-pos-hosting"} className="text-gray-400 hover:text-gray-200">QuickBooks POS Hosting</Link></li>
                            </ul>
                            <ul className="space-y-2">
                                <li><Link to={"/sage-hosting"} className="text-gray-400 hover:text-gray-200">Sage Hosting</Link></li>
                                <li><Link to={"/sage-50-hosting"} className="text-gray-400 hover:text-gray-200">Sage 50 Hosting</Link></li>
                                <li><Link to={"/sage-100-hosting"} className="text-gray-400 hover:text-gray-200">Sage 100 ERP Hosting</Link></li>
                                <li><Link to={"/sage-300-hosting"} className="text-gray-400 hover:text-gray-200">Sage 300 ERP Hosting</Link></li>
                                <li><Link to={"/sage-500-hosting"} className="text-gray-400 hover:text-gray-200">Sage 500 ERP Hosting</Link></li>
                            </ul>
                            <ul className="space-y-2">
                                <li><Link to={"/tax-hosting"} className="text-gray-400 hover:text-gray-200">Tax Software Hosting</Link></li>                   
                                <li><Link to={"/tax-drake-hosting"} className="text-gray-400 hover:text-gray-200">Drake Tax Software Hosting</Link></li>                   
                                <li><Link to={"/tax-wise-hosting"} className="text-gray-400 hover:text-gray-200">TaxWise Hosting</Link></li>
                                <li><Link to={"/ultra-tax-cs"} className="text-gray-400 hover:text-gray-200">UltraTax CS Hosting</Link></li>
                                <li><Link to={"/lacerte-hosting"} className="text-gray-400 hover:text-gray-200">Lacerte Hosting</Link></li>
                                <li><Link to={"/pro-series-hosting"} className="text-gray-400 hover:text-gray-200">ProSeries Tax Software Hosting</Link></li>
                            </ul>
                            <ul className="space-y-2">
                                <li><Link to={"/small-business"} className="text-gray-400 hover:text-gray-200">Small Business</Link></li>
                                <li><Link to={"/law-firm"} className="text-gray-400 hover:text-gray-200">Law Firm Hosting</Link></li>
                                <li><Link to={"/virtual-desktop"} className="text-gray-400 hover:text-gray-200">Virtual Desktop</Link></li>
                                <li><Link to={"/managed-it-services"} className="text-gray-400 hover:text-gray-200">Managed IT Services</Link></li>
                            </ul>
                        </div>
                    </div>

                    {/* Cloud Hosting Slogan */}
                   
                </div>

                {/* Footer Bottom */}
                <div className="border-t border-gray-700 pt-4 mt-8 text-center">
                    <p className="text-sm lg:text-base text-gray-400">&copy; {new Date().getFullYear()} CloudTech Solutions. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
