import React from "react";
import { FaShieldAlt, FaCloud, FaSync, FaUsers, FaServer, FaPhoneAlt, FaLock } from "react-icons/fa";
import { motion } from "framer-motion"; // For animation
import RequestTrialForm from './RequestTrialForm'

const FreeTrial = () => {


    const Benefits = () => {
        const benefitsData = [
            {
                icon: <FaShieldAlt size={40} className="text-blue-600" />,
                title: "Enhanced Security",
                description: "We provide top-notch, bank-grade data security to keep your data safe."
            },
            {
                icon: <FaCloud size={40} className="text-blue-600" />,
                title: "99.99% Uptime",
                description: "Our cloud infrastructure ensures your service is always available with zero downtime."
            },
            {
                icon: <FaSync size={40} className="text-blue-600" />,
                title: "Automated Backups",
                description: "Daily automated backups ensure that your data is always secure and recoverable."
            },
            {
                icon: <FaUsers size={40} className="text-blue-600" />,
                title: "Multi-user Collaboration",
                description: "Easily manage multiple users and permissions for seamless teamwork."
            },
            {
                icon: <FaServer size={40} className="text-blue-600" />,
                title: "Scalable Hosting",
                description: "Our platform scales with your business needs, whether small or enterprise-level."
            },
            {
                icon: <FaLock size={40} className="text-blue-600" />,
                title: "Privacy Protection",
                description: "We prioritize data privacy and compliance with strict security protocols."
            }
        ];

        return (
            <div className="w-full py-12 bg-gray-100">
                <h2 className="text-3xl font-bold text-center text-blue-600 mb-10">Why Choose ConciseNext and Free Trial?</h2>
                <div className="flex flex-wrap justify-center gap-8">
                    {benefitsData.map((benefit, index) => (
                        <motion.div
                            key={index}
                            className="w-full lg:w-1/3 bg-white p-6 rounded-lg shadow-lg transform transition-all hover:scale-105 hover:shadow-2xl"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.2 }}
                        >
                            <div className="flex items-center justify-center mb-4">
                                {benefit.icon}
                            </div>
                            <h3 className="text-xl font-semibold text-center mb-4">{benefit.title}</h3>
                            <p className="text-center text-gray-600">{benefit.description}</p>
                        </motion.div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center">
            {/* Hero Section */}
            <div
                className="w-full h-[500px] bg-cover bg-center text-white flex flex-col items-center justify-center relative"
                style={{ filter: "blur(10px)", backgroundImage: "url('https://media.licdn.com/dms/image/v2/D4D12AQEWT6rE-YGBNA/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1666802167861?e=2147483647&v=beta&t=dLeUIkD9hVm4B8PiV6qOdL6HD_YBH8yYdnvuTlH9uY4')" }} // Replace with your background image URL
            >


            </div>

            {/* Main Content */}
            <div className="w-11/12 lg:w-9/12 mx-auto flex flex-col lg:flex-row my-8 trial-from ">

                {/* Left Side: About ConciseNext and Free Trial */}
                <motion.div
                    className="w-full lg:w-1/2 p-8 flex flex-col text-left"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <motion.h1
                        className="text-4xl lg:text-5xl font-bold text-dark-600"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.7 }}
                    >
                        Get a Free Trial of ConciseNext Hosting!
                    </motion.h1>
                    <motion.p
                        className="mt-4 text-lg lg:text-xl text-dark-600"
                    >
                        Experience secure, fast, and reliable hosting at no cost.
                    </motion.p>

                    {/* Mobile Phone Button */}
                    <motion.a
                        href="tel:+1-855-922-7243"
                        className="text-4xl lg:text-5xl font-bold text-gray-800 hover:text-blue-800 transition duration-200 py-2 mt-6"
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.6, delay: 0.4 }}
                    >
                        <span>+1-1234-567-890</span>
                    </motion.a>

                    <h6 className="text-3xl text-gray-800 font-semibold mb-4 mt-8">About ConciseNext and Free Trial</h6>
                    <p className="text-gray-700 leading-relaxed mb-4">
                        ConciseNext offers state-of-the-art cloud hosting services tailored for both businesses and individuals.
                        With our free trial, you get a taste of top-tier performance, industry-leading security, and a variety
                        of tools to help you grow online. Our reliable infrastructure ensures zero downtime, while our user-friendly
                        platform allows for easy management of your applications and users. You’ll experience fast, responsive hosting
                        backed by 24/7 support and daily backups to keep your data secure. Sign up today to explore our customizable
                        solutions with no long-term commitment or credit card required.
                    </p>
                    <p className="text-gray-700 leading-relaxed">
                        During the free trial, you'll have full access to our cloud hosting platform, allowing you to test
                        applications, manage multiple users, and ensure compatibility with your needs. We also prioritize
                        privacy and provide top-notch data protection, ensuring peace of mind while you evaluate our services.
                    </p>
                </motion.div>



                <div className="w-full lg:w-1/2 bg-white p-6 shadow-lg rounded-lg border border-gray-200">
                    <h2 className="text-2xl font-bold text-left text-gray-800 mb-6">
                        Start Your Free Trial<br />No Credit Card. No Long-term Contract
                    </h2>
                    <RequestTrialForm />
                </div>





            </div >

            {/* <Benefits /> */}



        </div >

    );
};

export default FreeTrial;
