import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTools, FaExchangeAlt, FaHandshake, FaCloud, FaComments, FaDatabase, FaServer, FaHeadset, FaCogs, FaUserCheck, FaDollarSign, FaUserClock, FaLayerGroup, FaShieldAlt, FaLaptop, FaUserFriends, FaLock, FaBriefcase, FaTablet, FaTabletAlt, FaMobileAlt, FaBook, } from 'react-icons/fa';



export default function HostedVirtualDesktop() {

    const VirtualDesktopHero = () => {
        const { scrollY } = useScroll();
        const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
        const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
        const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
        const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

        return (
            <div className="relative h-[500px] md:h-[600px] bg-[url('https://www.techslang.com/wp-content/uploads/2022/09/SF_Oblako_tehnika_01.jpg')] bg-center bg-no-repeat bg-cover overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-gray-100 text-center px-4 md:px-6">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Hosted Virtual Desktop Solutions With <span className='text-blue-600 font-bold'> ConciseNext</span>
                    </motion.h1>
                    <motion.p
                        className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        Boost your productivity with ConciseNext’s Hosted Virtual Desktop Solution, providing secure remote access for seamless collaboration. Our scalable solutions simplify desktop management and enhance efficiency.
                    </motion.p>
                    <motion.a
                        href="#"
                        className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
                    >
                        Learn More
                    </motion.a>
                </div>

                {/* Parallax Image */}
                {/* <motion.img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf0J11mGCr4qq2ijT_M-BkcRpwBC9Hw_JKGP3_gcRakZAv1oSAWrooX9aS9uVTUX9gFF4&usqp=CAU"
                    alt="QuickBooks"
                    className="absolute bottom-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 right-0 transform translate-y-8 md:translate-y-16 sm:translate-y-0"
                    style={{ y: yImage }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                /> */}
            </div>
        );
    };



    const HostedVirtualDesktop = () => {
        return (
            <section className="bg-gray-50 py-16">
                <div className="container mx-auto px-6">
                    {/* Heading */}
                    <motion.h2
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="text-4xl font-bold text-center text-gray-800 mb-10"
                    >
                        What is a Hosted Virtual Desktop?
                    </motion.h2>

                    {/* Content Block */}
                    <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8 }}
                        className="text-lg text-center text-gray-600 mb-12 max-w-4xl mx-auto"
                    >
                        A Hosted Virtual Desktop (HVD) provides a cloud-based computing environment that mirrors a traditional desktop setup. This virtual desktop is hosted on a remote server and accessed through the internet, giving your team full access to their software, files, and settings from any device, whether it's a laptop, tablet, or smartphone.
                    </motion.div>

                    {/* Icons Section */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
                        {/* Icon Card 1 */}
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            viewport={{ once: true }}
                            className="flex flex-col items-center bg-white shadow-lg p-6 rounded-lg"
                        >
                            <FaCloud className="text-blue-600 w-14 h-14 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-700 mb-2">Cloud-Based</h3>
                            <p className="text-gray-600 text-center">Access your desktop through the cloud, providing secure, remote access.</p>
                        </motion.div>

                        {/* Icon Card 2 */}
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            viewport={{ once: true }}
                            className="flex flex-col items-center bg-white shadow-lg p-6 rounded-lg"
                        >
                            <FaServer className="text-green-600 w-14 h-14 mb-4" />
                            <h3 className="text-xl font-semibold text-gray-700 mb-2">Remote Server</h3>
                            <p className="text-gray-600 text-center">Your desktop environment is hosted on a secure, remote server.</p>
                        </motion.div>

                        {/* Icon Card 3 */}
                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            viewport={{ once: true }}
                            className="flex flex-col items-center bg-white shadow-lg p-6 rounded-lg"
                        >
                            <div className="flex space-x-2">
                                <FaLaptop className="text-purple-600 w-12 h-12" />
                                <FaTabletAlt className="text-purple-600 w-12 h-12" />
                                <FaMobileAlt className="text-purple-600 w-12 h-12" />
                            </div>
                            <h3 className="text-xl font-semibold text-gray-700 mt-4 mb-2">Access from Any Device</h3>
                            <p className="text-gray-600 text-center">Use your laptop, tablet, or smartphone to work seamlessly from anywhere.</p>
                        </motion.div>
                    </div>
                </div>
            </section>
        );
    };


    const IntroSection = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView && window.innerWidth > 768) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Hosted Virtual Desktop Solutions - ConciseNext
                </h2>

                <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
                    <img
                        src="https://oneclick-cloud.com/wp-content/uploads/2023/08/virtual-desktop-infrastructure-cloud-man@2x.png"
                        alt="QuickBooks License"
                        className="rounded-lg shadow-lg w-[400px] h-[auto]"
                    />
                    <p className="text-lg text-gray-700 leading-relaxed text-center md:text-left">
                        Transform the way your business operates with ConciseNext's Hosted Virtual Desktop Solutions. We offer secure, cloud-based desktop environments that allow you and your team to work from anywhere, anytime, on any device. With a hosted virtual desktop (HVD), you can eliminate the need for costly physical infrastructure, improve collaboration, and enhance data security—all while boosting productivity
                    </p>
                </div>

                <h3 className="text-3xl pt-8 font-bold text-gray-800 text-center md:text-left">
                    Why Choose ConciseNext for Hosted Virtual Desktop Solutions?
                </h3>

                <ul className="space-y-8">
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaLaptop className="w-8 h-8 text-blue-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Anytime, Anywhere Access</h4>
                            <p className="text-lg text-gray-700">
                                Your team can securely access their virtual desktop from any location, making it easier to work remotely or on the go. All files, applications, and settings are available instantly, just as if they were working from the office.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaUserFriends className="w-8 h-8 text-green-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Scalability</h4>
                            <p className="text-lg text-gray-700">
                                As your business grows, our hosted virtual desktops can scale with you. Whether you need to add more users, storage, or resources, our solution can be customized to meet your evolving needs without the need for new hardware.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaShieldAlt className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Security First</h4>
                            <p className="text-lg text-gray-700">
                                We prioritize data security with enterprise-grade encryption, multi-factor authentication, and regular security audits. With ConciseNext's Hosted Virtual Desktops, your data is protected from unauthorized access, loss, or theft.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaDollarSign className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cost-Effective IT Solutions</h4>
                            <p className="text-lg text-gray-700">
                                By moving to a virtual desktop environment, you reduce the need for physical hardware and IT maintenance, cutting your business’s operational costs. All updates, backups, and server maintenance are handled by ConciseNext.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaUserCheck className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Seamless User Experience</h4>
                            <p className="text-lg text-gray-700">
                                : Your team enjoys a consistent user experience, whether they’re working in the office or remotely. Applications, data, and settings remain the same across all devices, ensuring seamless workflows and increased productivity.
                            </p>
                        </div>
                    </li>

                </ul>
            </motion.div>
        );
    };

    const Benefits = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto pt-8 p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Key Features of ConciseNext Hosted Virtual Desktops
                </h2>

                {/* License Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
                    {[
                        {
                            title: '24/7 Access',
                            description:
                                'Allow your team to work whenever and wherever they need with secure, remote access to their desktops and applications.',
                            imageUrl:
                                'https://imageio.forbes.com/specials-images/imageserve/64c28d7071424270e316a7fe/Data-protection-privacy-concept--Business-man-protecting-his-personal-data-/960x0.jpg?format=jpg&width=960',
                            link: '/quickbooks-pro-hosting',
                        },
                        {
                            title: 'Full Customization',
                            description:
                                'Tailor your virtual desktop environment to suit your business needs. Install the necessary applications, set user permissions, and allocate resources based on individual or team requirements.',
                            imageUrl:
                                'https://www.executivegrapevine.com/uploads/articles/hr-learn20-gpstrategies-thumb.jpg',
                            link: '#',
                        },

                        {
                            title: 'High Availability and Uptime',
                            description:
                                'We guarantee 99.9% uptime for your hosted desktop environment, ensuring that your business operations are never interrupted. Our robust cloud infrastructure ensures high availability, even during peak usage.',
                            imageUrl:
                                'https://www.voipreview.org/sites/voipreview.org/files/styles/blog_post/public/blog/featuredt_images/2019/03/best-online-tech-support-services.jpg?itok=bkbqdkFd',
                            link: '#',
                        },
                        {
                            title: 'Built-In Disaster Recovery',
                            description:
                                'With automatic backups and disaster recovery protocols, your data is safe even in the event of an unforeseen outage or emergency. We ensure that your business can recover quickly with minimal downtime.',
                            imageUrl:
                                'https://www.gep.com/prod/s3fs-public/blog-images/technology-driven-workplace-transformation-and-how-to-go-about-it.jpg',
                            link: '#',
                        },
                        {
                            title: 'Cross-Platform Compatibility',
                            description:
                                'Access your virtual desktop from any operating system, whether it’s Windows, macOS, iOS, or Android. Your team can work from any device, providing maximum flexibility and efficiency.',
                            imageUrl:
                                'https://www.gep.com/prod/s3fs-public/blog-images/technology-driven-workplace-transformation-and-how-to-go-about-it.jpg',
                            link: '#',
                        },
                        {
                            title: 'Dedicated Support',
                            description:
                                'Our technical support team is available 24/7 to help resolve any issues or answer questions. We provide round-the-clock assistance to ensure that your virtual desktop environment runs smoothly.',
                            imageUrl:
                                'https://www.gep.com/prod/s3fs-public/blog-images/technology-driven-workplace-transformation-and-how-to-go-about-it.jpg',
                            link: '#',
                        },
                    ].map((license, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            className="bg-white rounded-lg shadow-lg p-5 flex flex-col items-center text-start space-y-4 transition-transform duration-300"
                        >
                            <img
                                src={license.imageUrl}
                                alt={license.title}
                                className="rounded-lg shadow-lg w-full h-48 object-cover"
                            />
                            <h4 className="text-xl font-semibold text-gray-800">
                                {license.title}
                            </h4>
                            <p className="text-gray-600 pb-10">{license.description}</p>

                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };


    const VirtualDesktopSolutions = () => {
        const controls = useAnimation();
        const { ref, inView } = useInView({ threshold: 0.2 });

        React.useEffect(() => {
            if (inView) {
                controls.start('visible');
            } else {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const fadeInVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
        };
        const isLargeScreen = window.innerWidth >= 768;

        return (
            <div className="bg-gray-100 text-gray-800 py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold text-center mb-12">Why Businesses Trust ConciseNext</h2>

                    <div ref={ref} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-blue-500"
                            initial="hidden"
                            animate={isLargeScreen ? controls : 'visible'}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaCloud className="text-blue-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Experience and Expertise</h3>
                            </div>
                            <p className="text-lg mb-6">
                                With years of experience in cloud hosting and IT management, ConciseNext is a trusted partner for businesses across multiple industries. Our expertise ensures that you get the best virtual desktop solution tailored to your specific needs.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 hover:bg-red-700 hover:text-white transition-colors rounded-md">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-green-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaServer className="text-green-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Proven Track Record</h3>
                            </div>
                            <p className="text-lg mb-6">
                                We have successfully provided hosting solutions to businesses of all sizes, from small startups to large enterprises. Our clients trust us for our reliability, security, and exceptional customer service.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-md hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-red-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaShieldAlt className="text-red-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Advanced Security</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Data security is a top priority at ConciseNext. We employ cutting-edge security technologies to protect your business’s sensitive information and ensure compliance with data protection regulations
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-lg hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>
                    </div>
                </div>
            </div>
        );
    };



    const HostingWorks = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-6xl mx-auto p-8 pt-8 space-y-12 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    How ConciseNext Hosted Virtual Desktop Solutions Work
                </h2>

                <div className="relative">
                    <div className="absolute left-1/2 top-0 transform -translate-x-1/2 h-full border-l-2 border-dashed border-gray-300" />
                    <div className="space-y-8">
                        {[
                            {
                                step: 'Consultation',
                                description: 'We begin by assessing your business’s IT needs, including software requirements, user numbers, and security concerns. Based on this assessment, we design a customized virtual desktop solution.',
                                icon: <FaComments className="w-10 h-10 text-blue-500" />,
                            },
                            {
                                step: 'Setup and Migration',
                                description: 'Our team migrates your existing data, applications, and settings to our secure cloud environment. We ensure minimal downtime during the transition, so your team can get back to work quickly.',
                                icon: <FaExchangeAlt className="w-10 h-10 text-green-500" />,
                            },
                            {
                                step: 'Access and Work',
                                description: 'Once your hosted virtual desktops are live, your team can securely log in from any device, anywhere in the world. They will have access to all their familiar applications, files, and settings.',
                                icon: <FaCloud className="w-10 h-10 text-purple-500" />,
                            },
                            {
                                step: 'Ongoing Management',
                                description: 'ConciseNext handles all IT management tasks, including software updates, backups, security patches, and resource scaling. This frees your team from IT management concerns, allowing them to focus on their work.',

                                icon: <FaHeadset className="w-10 h-10 text-red-500" />,
                            },
                        ].map((step, index) => (
                            <div key={index} className="relative bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center space-y-4">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 flex items-center justify-center bg-white rounded-full border-4 border-gray-200">
                                    {step.icon}
                                </div>
                                <div className="mt-12">
                                    <h3 className="text-2xl font-semibold text-gray-800">{step.step}</h3>
                                    <p className="text-lg text-gray-600 mt-2">{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        );
    };


    const WhyChooseConciseNext = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const cardVariants = {
            hidden: { opacity: 0, y: 10 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.6,
                    ease: "easeOut",
                    when: "beforeChildren",
                    staggerChildren: 0.3,
                },
            },
        };

        const iconVariants = {
            hidden: { scale: 0 },
            visible: {
                scale: 1,
                transition: {
                    duration: 0.5,
                    ease: "backOut",
                },
            },
        };
        const isLargeScreen = window.innerWidth >= 768;
        return (
            <div className="bg-gray-50 py-5">
                <div className="max-w-7xl mx-auto px-6 sm:px-8">
                    <motion.h2
                        className="text-4xl font-bold text-center text-gray-800 mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Benefits for Your Business
                    </motion.h2>

                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={isLargeScreen ? controls : 'visible'} s
                        variants={cardVariants}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8"
                    >
                        {[
                            {
                                title: "Enhanced Collaboration",
                                description:
                                    "With a centralized virtual desktop environment, your team can collaborate on projects in real-time, no matter where they’re located.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-blue-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaBriefcase size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Business Continuity",
                                description:
                                    "In the event of an office closure or natural disaster, your business can continue uninterrupted. Employees can access their virtual desktops from home or any remote location, ensuring business continuity.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-green-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaTablet size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Improved Security",
                                description:
                                    "All data is stored in our secure cloud environment, reducing the risk of data breaches, hardware loss, or cyber threats. Our advanced security measures ensure compliance with industry standards.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaShieldAlt size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Reduced IT Costs",
                                description:
                                    "Say goodbye to expensive hardware purchases, server maintenance, and frequent IT support costs. Our hosted virtual desktops eliminate the need for physical infrastructure, saving you time and money.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaDollarSign size={48} />
                                    </motion.div>
                                ),
                            },
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-lg shadow-lg text-start flex flex-col items-center"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    };


    const HostingSolutions = () => {
        const solutions = [
            {
                icon: <FaCloud className="text-blue-600 w-14 h-14" />,
                title: "Accounting & Financial Firms",
                description: "Work securely with sensitive financial data and access your accounting software from anywhere..",
            },
            {
                icon: <FaLock className="text-green-600 w-14 h-14" />,
                title: "Legal Firms",
                description: "Manage case files, legal software, and client information securely in the cloud.",
            },
            {
                icon: <FaBriefcase className="text-purple-600 w-14 h-14" />,
                title: "Healthcare Providers",
                description: "Ensure compliance with HIPAA and other regulations while accessing medical records and practice management software remotely.",
            },
            {
                icon: <FaTools className="text-red-600 w-14 h-14" />,
                title: "Manufacturing & Retail",
                description: "Manage supply chains, inventory, and customer orders with secure remote access to enterprise applications.",
            },
            {
                icon: <FaBook className="text-red-600 w-14 h-14" />,
                title: "Education & Nonprofits",
                description: "Give students, faculty, or staff easy access to educational software and resources, whether in the classroom or remotely.",
            },
        ];

        return (
            <section className="bg-gray-100 py-8">
                <div className="container mx-auto px-6">
                    <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
                        Industries We Serve
                    </h2>

                    <div className="space-y-8">
                        {solutions.map((solution, index) => (
                            <AnimatedCard
                                key={index}
                                icon={solution.icon}
                                title={solution.title}
                                description={solution.description}
                                delay={index * 0.2}
                            />
                        ))}
                    </div>
                </div>
            </section>
        );
    };

    const AnimatedCard = ({ icon, title, description, delay }) => {
        const [ref, inView] = useInView({
            triggerOnce: true,
            threshold: 0.2,
        });

        const cardVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
        };

        // Determine if animation should be applied based on screen size
        const isLargeScreen = window.innerWidth > 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={inView && isLargeScreen ? "visible" : "hidden"}
                variants={isLargeScreen ? cardVariants : {}}
                transition={{ duration: 0.4, delay }}
                className="flex items-center bg-white shadow-lg rounded-lg p-6 hover:bg-blue-50 transition-all"
            >
                <div className="mr-6">
                    {icon}
                </div>
                <div>
                    <h3 className="text-2xl font-semibold text-gray-700 mb-2">
                        {title}
                    </h3>
                    <p className="text-gray-600">
                        {description}
                    </p>
                </div>
            </motion.div>
        );
    };




    const SoftwareVersion = () => {
        const containerVariants = {
            hidden: { opacity: 0, y: 20 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    staggerChildren: 0.3,
                    duration: 0.5,
                    ease: 'easeInOut',
                },
            },
        };

        const itemVariants = {
            hidden: { opacity: 0, y: 10 },
            visible: { opacity: 1, y: 0 },
        };

        const softwareList = [
            { name: 'Drake Tax Software', icon: FaDatabase },
            { name: 'Lacerte Tax Software', icon: FaCloud },
            { name: 'ProSeries Tax Software', icon: FaLock },
            { name: 'UltraTax CS', icon: FaTools },
            { name: 'ProSystem fx', icon: FaCloud },
        ];

        return (
            <div className="relative bg-blue-50 py-16 px-6">
                {/* Background Image */}
                <div className="absolute inset-0 bg-[url('https://cdn.pixabay.com/photo/2024/05/31/06/48/ai-generated-8799924_1280.jpg')] bg-cover bg-center opacity-20"></div>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                    className="relative max-w-6xl mx-auto text-center bg-white p-8 rounded-lg shadow-lg"
                >


                    {/* Divider */}
                    <div className="mt-12">
                        <hr className="border-blue-300 mb-8" />

                        {/* TaxWise Hosting Section with Image */}
                        <motion.div variants={containerVariants} className="text-left">
                            <motion.h3
                                variants={itemVariants}
                                className="text-3xl font-bold text-blue-900 mb-4"
                            >
                                Ready to Elevate Your Business with Virtual Desktops?
                            </motion.h3>
                            <div className="flex flex-col md:flex-row md:items-center">
                                <motion.p
                                    variants={itemVariants}
                                    className="text-lg text-blue-700 leading-relaxed md:w-1/2"
                                >
                                    Join the many businesses that are transforming their operations with ConciseNext's Hosted Virtual Desktop Solutions. Whether you need flexibility, security, or scalability, our hosted virtual desktops give your team the tools they need to succeed.
                                </motion.p>
                                {/* Image with transition */}
                                <motion.img
                                    src="https://cdn.pixabay.com/photo/2024/05/31/06/48/ai-generated-8799924_1280.jpg"
                                    alt="TaxWise Hosting"
                                    className="rounded-lg shadow-lg w-full md:w-1/2 mt-6 md:mt-0 md:ml-8"
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5 }}
                                />
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        );
    };


    const ContactUs = () => {
        return (
            <section className="relative bg-gray-100 py-6 px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="absolute inset-0">
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 translate-y-1/4 -z-10" width="404" height="404" fill="none" viewBox="0 0 404 404">
                        <defs>
                            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-indigo-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#pattern1)" />
                    </svg>
                </div>
                <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <motion.h2
                        className="text-3xl font-extrabold text-gray-900 mb-6"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        Contact Us
                    </motion.h2>
                    <div className="flex flex-col items-center">
                        <motion.div
                            className="text-gray-800 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
                        >
                            <p className="text-lg font-semibold">📞 Call us: +1 (800) 123-4567</p>
                            <p className="text-lg font-semibold">📧 Email us: <a href="mailto:sales@ConciseNext.com" className="text-indigo-600 hover:underline">sales@ConciseNext.com</a></p>
                        </motion.div>
                        <motion.p
                            className="text-gray-500"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
                        >
                            ConciseNext – Your trusted partner for secure, scalable, and cost-effective cloud hosting solutions.
                        </motion.p>
                    </div>
                </div>
            </section>
        );
    };




    return (
        <div>
            <VirtualDesktopHero />
            <HostedVirtualDesktop />
            <IntroSection />
            <Benefits />
            <VirtualDesktopHero />
            <HostingWorks />
            <HostingSolutions />
            <WhyChooseConciseNext />
            <SoftwareVersion />
            <ContactUs />
        </div>
    )

}