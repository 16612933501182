import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTools, FaExchangeAlt, FaHandshake, FaCloud, FaComments, FaDatabase, FaServer, FaHeadset, FaCogs, FaUserCheck, FaDollarSign, FaUserClock, FaLayerGroup, FaShieldAlt, FaLaptop, FaReact, FaExpand, FaPlaystation, } from 'react-icons/fa';





export default function Sage100Hosting() {

    const SageHero = () => {
        const { scrollY } = useScroll();
        const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
        const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
        const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
        const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

        return (
            <div className="relative h-[500px] md:h-[600px]  bg-[url('https://img.pikbest.com/wp/202405/cloud-server-illustration-of-computing-technology-with-3d-and-laptops_9855617.jpg!w700wp')] bg-cover bg-center bg-no-repeat overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Sage 100 ERP Hosting With <span className='text-blue-500 font-bold'>ConciseNext</span>
                    </motion.h1>
                    <motion.p
                        className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        Unlock the full capabilities of Sage 100 with ConciseNext's advanced cloud hosting solutions. Effortlessly manage your business from anywhere, anytime, with unparalleled security, scalability, and expert support. Revolutionize your operations with our dependable and innovative hosting services.
                    </motion.p>
                    <motion.a
                        href="#"
                        className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
                    >
                        Learn More
                    </motion.a>
                </div>

                {/* Parallax Image */}
                <motion.img
                    src="https://www.netatwork.com/wp-content/uploads/2018/05/cloudatwork-devices-sage100.png"
                    alt="Sage 50"
                    className="absolute bottom-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 right-0 transform translate-y-8 md:translate-y-16 sm:translate-y-0"
                    style={{ y: yImage }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                />
            </div>
        );
    };



    const SageIntroSection = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView && window.innerWidth > 768) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto p-8 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Sage 100 ERP Hosting Services - ConciseNext
                </h2>

                <div className="flex flex-col md:flex-row gap-8 justify-center items-center">
                    <img
                        src="https://www.dckap.com/wp-content/uploads/2023/11/D-blog2.png"
                        alt="Sage 50"
                        className="rounded-lg shadow-lg w-[400px] h-[auto]"
                    />
                    <p className="text-lg text-gray-700 leading-relaxed text-center md:text-left">
                        Unlock the full potential of your business with ConciseNext’s Sage 100 ERP Hosting Services. Our secure, high-performance cloud hosting allows you to access your Sage 100 ERP software from anywhere, ensuring that your business operations run smoothly, efficiently, and with real-time collaboration.
                    </p>
                </div>

                <h3 className="text-3xl pt-8 font-bold text-gray-800 text-center md:text-left">
                    Why Choose ConciseNext for Sage 100 ERP Hosting?
                </h3>

                <ul className="space-y-8">
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaLaptop className="w-8 h-8 text-blue-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Anytime, Anywhere Access</h4>
                            <p className="text-lg text-gray-700">
                                With our Sage 100 ERP cloud hosting, your team can securely access your ERP system from any location, on any device. Whether you're working remotely or in the office, enjoy uninterrupted access to manage accounting, finance, distribution, and manufacturing tasks.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaDollarSign className="w-8 h-8 text-green-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cost Savings</h4>
                            <p className="text-lg text-gray-700">
                                Reduce overhead costs by eliminating the need for in-house servers and IT maintenance. Our cloud hosting services offer flexible pricing models that allow you to scale based on your business needs, without the expense of hardware investments.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaReact className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Enhanced Collaboration</h4>
                            <p className="text-lg text-gray-700">
                                Hosting Sage 100 in the cloud makes it easy for multiple users to work simultaneously, improving communication, productivity, and decision-making across departments.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaShieldAlt className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Top-Level Security</h4>
                            <p className="text-lg text-gray-700">
                                We prioritize the security of your data. Our hosting environment is protected with cutting-edge encryption, multi-layered firewalls, and automated daily backups, ensuring that your financial and operational data is secure at all times.


                            </p>
                        </div>
                    </li>

                </ul>
            </motion.div>
        );
    };

    const SageBenefits = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto pt-10 p-7 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Key Benefits of Sage 100 ERP Hosting with ConciseNext
                </h2>

                {/* License Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
                    {[
                        {
                            title: 'High Availability',
                            description:
                                'We guarantee 99.9% uptime, ensuring your Sage 100 ERP software is available whenever you need it. Our powerful cloud infrastructure keeps your business running smoothly, with minimal disruption.',
                            imageUrl:
                                'https://www.serviceobjects.com/blog/wp-content/uploads/2020/12/technician-with-network-equipment-and-cables-picture-id160840867.jpg',
                            link: '#',
                        },
                        {
                            title: 'Fully Managed Hosting',
                            description:
                                'Our team of cloud experts takes care of everything from server management to software updates, backups, and security patches. This leaves you free to focus on core business activities, while we ensure your Sage 100 ERP is running at peak performance.',
                            imageUrl:
                                'https://bitperfection.com/wp-content/uploads/2020/09/Data-Backup.png',
                            link: '#',
                        },

                        {
                            title: '24/7 Dedicated Support',
                            description:
                                'With our around-the-clock technical support, you can rest easy knowing our team is available to help with any issues or questions related to your hosted Sage 100 environment.',
                            imageUrl:
                                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9QV5uZGpNH5QDod-mffyT7A-X4RkJk7LIAQ&s',
                            link: '#',
                        },
                        {
                            title: 'No Hassle Migration',
                            description:
                                'Transitioning your Sage 100 ERP to the cloud is a breeze with our dedicated migration team. We ensure a seamless, worry-free migration process with zero data loss and minimal downtime.',
                            imageUrl:
                                'https://embrace-digital.co.uk/wp-content/uploads/2022/11/technical-support-1.jpg',
                            link: '#',
                        },
                    ].map((license, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-start space-y-4 transition-transform duration-300"
                        >
                            <img
                                src={license.imageUrl}
                                alt={license.title}
                                className="rounded-lg shadow-lg w-full h-48 object-cover"
                            />
                            <h4 className="text-xl font-semibold text-gray-800">
                                {license.title}
                            </h4>
                            <p className="text-gray-600 pb-10">{license.description}</p>

                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };



    const GetStartedSection = () => {
        return (
            <section className="relative bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 py-16 text-white">
                <div className="absolute inset-0 bg-black opacity-30"></div>
                <div className="relative max-w-7xl mx-auto px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-4xl font-bold mb-4">
                            Planning to Get Started with Hosted Sage 100 ERP?
                        </h2>
                        <p className="text-lg mb-8">
                            Explore the advantages of our cloud-hosted Sage 100 ERP solutions. Optimize your business processes and boost productivity with our premier hosting services, designed to deliver seamless performance and robust scalability.
                        </p>
                        <a
                            href="#"
                            className="bg-button text-white py-3 px-6 rounded-lg font-semibold text-lg transition-transform transform hover:scale-105 hover:bg-red-600"
                        >
                            Get Free Trial
                        </a>
                    </div>
                </div>
            </section>
        );
    };



    const SageHostingWorks = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-6xl mx-auto p-8 pt-12 space-y-12 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    How Our Sage 100 ERP Hosting Works
                </h2>

                <div className="relative">
                    <div className="absolute left-1/2 top-0 transform -translate-x-1/2 h-full border-l-2 border-dashed border-gray-300" />
                    <div className="space-y-8">
                        {[
                            {
                                step: 'Consultation',
                                description: 'Get in touch with us to discuss your Sage 100 ERP needs. We’ll tailor a cloud hosting solution that best fits your business requirements.',
                                icon: <FaComments className="w-10 h-10 text-blue-500" />,
                            },
                            {
                                step: 'Migration',
                                description: 'Our experienced migration team handles everything, from securely transferring your data to configuring the cloud environment. We ensure minimal disruption to your business operations during the transition.',
                                icon: <FaExchangeAlt className="w-10 h-10 text-green-500" />,
                            },
                            {
                                step: 'Access & Use',
                                description: 'Once hosted, you’ll enjoy full access to Sage 100 ERP from any internet-connected device, providing your team with the flexibility to work from anywhere while keeping critical business data at their fingertips.',
                                icon: <FaCloud className="w-10 h-10 text-purple-500" />,
                            },
                            {
                                step: 'Ongoing Maintenance & Support',
                                description: 'We continuously monitor your Sage 100 ERP environment, ensuring peak performance with automatic updates, regular backups, and 24/7 technical support.',
                                icon: <FaHeadset className="w-10 h-10 text-red-500" />,
                            },
                        ].map((step, index) => (
                            <div key={index} className="relative bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center space-y-4">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 flex items-center justify-center bg-white rounded-full border-4 border-gray-200">
                                    {step.icon}
                                </div>
                                <div className="mt-12">
                                    <h3 className="text-2xl font-semibold text-gray-800">{step.step}</h3>
                                    <p className="text-lg text-gray-600 mt-2">{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        );
    };


    const WhyChooseConciseNext = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const cardVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.6,
                    ease: "easeOut",
                    when: "beforeChildren",
                    staggerChildren: 0.3,
                },
            },
        };

        const iconVariants = {
            hidden: { scale: 0 },
            visible: {
                scale: 1,
                transition: {
                    duration: 0.5,
                    ease: "backOut",
                },
            },
        };

        return (
            <div className="bg-gray-50 py-10">
                <div className="max-w-7xl mx-auto px-6 sm:px-8">
                    <motion.h2
                        className="text-4xl font-bold text-center text-gray-800 mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Why Businesses Choose ConciseNext for Sage 100 ERP Hosting
                    </motion.h2>

                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={cardVariants}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8"
                    >
                        {[
                            {
                                title: "Trusted Expertise",
                                description:
                                    "At ConciseNext, we specialize in hosting accounting and ERP software like Sage 100. Our team understands the intricacies of ERP systems, delivering hosting solutions that optimize performance and meet your business needs.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-blue-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaExpand size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Customizable Hosting Plans",
                                description:
                                    "Whether you need a hosting solution for a small operation or an enterprise-level business, we offer customizable hosting plans that allow you to scale resources as your company grows.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-green-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaPlaystation size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Advanced Security & Compliance",
                                description:
                                    "We ensure your data is fully protected with SOC-certified data centers, two-factor authentication, encrypted data transmission, and regular security audits. Our hosting meets stringent compliance requirements to safeguard your business’s most sensitive information.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaShieldAlt size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "implified IT Management",
                                description:
                                    "Let us handle the complex IT tasks. With ConciseNext, you get a fully managed cloud hosting service, including maintenance, monitoring, and disaster recovery, allowing you to focus on growing your business.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaLaptop size={48} />
                                    </motion.div>
                                ),
                            },
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-lg shadow-lg text-start flex flex-col items-center"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    };

    const Sage50HostingPlans = () => {
        // Variants for scroll animation
        const variants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
        };

        return (
            <div className="max-w-7xl mx-auto p-8">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
                    Sage 100 ERP Hosting Plans We Offer
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Single-User Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Single-User Sage 100 ERP Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            Ideal for small businesses needing a secure and reliable cloud environment to manage their operations with Sage 100 ERP.
                        </p>
                        <a
                            href="#"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Learn More
                        </a>
                    </motion.div>
                    {/* Multi-User Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Multi-User Sage 100 ERP Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            Designed for growing businesses, this plan supports multiple users, enabling real-time collaboration and access to shared data without performance lag.
                        </p>
                        <a
                            href="#"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Learn More
                        </a>
                    </motion.div>
                    {/* Custom Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Enterprise-Level Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            For larger businesses with more complex requirements, we offer enterprise-level hosting solutions that provide the highest level of performance, security, and scalability for Sage 100 ERP.
                        </p>
                        <a
                            href="#"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Learn More
                        </a>
                    </motion.div>
                </div>
            </div>
        );
    };


    const ContactUs = () => {
        return (
            <section className="relative bg-gray-100 py-8 px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="absolute inset-0">
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 translate-y-1/4 -z-10" width="404" height="404" fill="none" viewBox="0 0 404 404">
                        <defs>
                            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-indigo-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#pattern1)" />
                    </svg>
                </div>
                <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <motion.h2
                        className="text-3xl font-extrabold text-gray-900 mb-6"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        Start Your Sage 100 ERP Cloud Journey Today!
                    </motion.h2>
                    <motion.p
                        className="text-lg text-gray-600 mb-8"
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut', delay: 0.2 }}
                    >
                        Experience the benefits of Sage 100 ERP cloud hosting with ConciseNext. Enhance productivity, improve security, and reduce costs by moving your ERP system to the cloud. Our team is here to help you every step of the way, from consultation to implementation and beyond.
                    </motion.p>
                    <div className="flex flex-col items-center">
                        <motion.div
                            className="text-gray-800 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
                        >
                            <p className="text-lg font-semibold">📞 Call us: +1 (800) 123-4567</p>
                            <p className="text-lg font-semibold">📧 Email us: <a href="mailto:sales@ConciseNext.com" className="text-indigo-600 hover:underline">sales@ConciseNext.com</a></p>
                        </motion.div>
                        <motion.p
                            className="text-gray-500"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
                        >
                            ConciseNext – Your trusted provider for reliable and secure Sage 100 ERP hosting solutions.
                        </motion.p>
                    </div>
                </div>
            </section>
        );
    };





    return (
        <div>
            <SageHero />
            <SageIntroSection />
            <SageBenefits />
            <SageHostingWorks />
            <WhyChooseConciseNext />
            <GetStartedSection />
            <Sage50HostingPlans />
            <ContactUs />

            
        </div>
    )

}