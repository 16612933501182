import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTools, FaExchangeAlt, FaHandshake, FaCloud, FaComments, FaDatabase, FaServer, FaHeadset, FaCogs, FaUserCheck, FaDollarSign, FaUserClock, FaLayerGroup, FaShieldAlt, FaLaptop, FaReact, FaExpand, FaPlaystation, } from 'react-icons/fa';





export default function Sage50Hosting() {

    const SageHero = () => {
        const { scrollY } = useScroll();
        const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
        const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
        const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
        const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

        return (
            <div className="relative  bg-[url('https://resources.learnquest.com/wp-content/uploads/2023/07/Client-Server-Network-Model.jpg')] bg-center h-[500px] md:h-[600px] bg-no-repeat bg-cover overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Sage 50 Hosting With <span className='text-blue-500 font-bold'>ConciseNext</span>
                    </motion.h1>
                    <motion.p
                        className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        Experience the full potential of Sage 50 with ConciseNext’s cutting-edge cloud hosting solutions. Seamlessly access your Sage software from any location, at any time, with exceptional security, flexibility, and dedicated support. Transform your business operations with our reliable and innovative hosting services.
                    </motion.p>
                    <motion.a
                        href="#"
                        className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
                    >
                        Learn More
                    </motion.a>
                </div>

                {/* Parallax Image */}
                <motion.img
                    src="https://www.procurementexpress.com/wp-content/uploads/2021/06/Sage-50-hero-image-1.png"
                    alt="Sage 50"
                    className="absolute bottom-5 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 right-5 transform translate-y-8 md:translate-y-16 sm:translate-y-0"
                    style={{ y: yImage }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                />
            </div>
        );
    };



    const SageIntroSection = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView && window.innerWidth > 768) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto p-7 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Sage 50 Hosting Services - ConciseNext
                </h2>

                <div className="flex flex-col md:flex-row gap-8 justify-center items-center">
                    <img
                        src="https://www.acs-dxb.com/uploads/2023/06/648dd42f77bcc_ACS%20Blog%20Banner%20(2).png"
                        alt="Sage 50"
                        className="rounded-lg shadow-lg w-[400px] h-[auto]"
                    />
                    <p className="text-lg text-gray-700 leading-relaxed text-center md:text-left">
                        Move your accounting operations to the cloud with ConciseNext's Sage 50 Hosting Services. We offer a fully managed, secure, and flexible solution that lets you access your Sage 50 accounting software from anywhere, on any device, while ensuring high performance and data security.
                    </p>
                </div>

                <h3 className="text-3xl pt-8 font-bold text-gray-800 text-center md:text-left">
                    Why Choose ConciseNext for Sage 50 Hosting?
                </h3>

                <ul className="space-y-8">
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaLaptop className="w-8 h-8 text-blue-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Remote Access Anytime, Anywhere</h4>
                            <p className="text-lg text-gray-700">
                                With our Sage 50 cloud hosting, you can access your accounting data from any location, ensuring you never miss a beat whether you’re working from the office, home, or on the go..
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaDollarSign className="w-8 h-8 text-green-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cost-Effective Solutions</h4>
                            <p className="text-lg text-gray-700">
                                Eliminate the need for expensive in-house servers and IT management. Our Sage 50 hosting provides a cost-effective way to enjoy the benefits of cloud computing without the overhead costs of maintaining your own infrastructure.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaReact className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Real-Time Collaboration</h4>
                            <p className="text-lg text-gray-700">
                                Host your Sage 50 software in the cloud and allow multiple users to work simultaneously. Share real-time data with your team, improving accuracy and speeding up decision-making.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaShieldAlt className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Enhanced Data Security</h4>
                            <p className="text-lg text-gray-700">
                                Our top-tier cloud environment is equipped with advanced security protocols such as encryption, firewalls, and regular backups, ensuring your data is always safe and protected.


                            </p>
                        </div>
                    </li>

                </ul>
            </motion.div>
        );
    };

    const SageBenefits = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto pt-3 p-4 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Benefits of Hosting Sage 50 with ConciseNext
                </h2>

                {/* License Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
                    {[
                        {
                            title: '99.9% Uptime Guarantee',
                            description:
                                'We provide a stable and reliable cloud environment with minimal downtime, ensuring your Sage 50 application is always accessible when you need it.',
                            imageUrl:
                                'https://www.serviceobjects.com/blog/wp-content/uploads/2020/12/technician-with-network-equipment-and-cables-picture-id160840867.jpg',
                            link: '#',
                        },
                        {
                            title: 'Automatic Backups',
                            description:
                                'Your data is backed up automatically, reducing the risk of data loss. You’ll always have a secure copy of your financial information.',
                            imageUrl:
                                'https://bitperfection.com/wp-content/uploads/2020/09/Data-Backup.png',
                            link: '#',
                        },

                        {
                            title: '24/7 Technical Support',
                            description:
                                'Our expert support team is available round-the-clock to help with any questions, issues, or technical assistance you may need regarding your hosted Sage 50 environment.',
                            imageUrl:
                                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9QV5uZGpNH5QDod-mffyT7A-X4RkJk7LIAQ&s',
                            link: '#',
                        },
                        {
                            title: 'No Software Management Hassles',
                            description:
                                'We handle all software updates, server maintenance, and security patches, so you can focus on running your business while we take care of the technical side.',
                            imageUrl:
                                'https://embrace-digital.co.uk/wp-content/uploads/2022/11/technical-support-1.jpg',
                            link: '#',
                        },
                    ].map((license, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center text-start space-y-4 transition-transform duration-300"
                        >
                            <img
                                src={license.imageUrl}
                                alt={license.title}
                                className="rounded-lg shadow-lg w-full h-48 object-cover"
                            />
                            <h4 className="text-xl font-semibold text-gray-800">
                                {license.title}
                            </h4>
                            <p className="text-gray-600 pb-10">{license.description}</p>

                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };



    const GetStartedSection = () => {
        return (
            <section className="relative bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 py-16 text-white">
                <div className="absolute inset-0 bg-black opacity-30"></div>
                <div className="relative max-w-7xl mx-auto px-6 lg:px-8">
                    <div className="text-center">
                        <h2 className="text-4xl font-bold mb-4">
                            Planning to Get Started with Hosted Sage 50?
                        </h2>
                        <p className="text-lg mb-8">
                            Discover the benefits of our cloud-hosted Sage 50 solutions. Streamline your accounting and enhance your business's productivity with our top-notch hosting services.
                        </p>
                        <a
                            href="#"
                            className="bg-button text-white py-3 px-6 rounded-lg font-semibold text-lg transition-transform transform hover:scale-105 hover:bg-red-600"
                        >
                            Get Free Trial
                        </a>
                    </div>
                </div>
            </section>
        );
    };



    const SageHostingWorks = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-6xl mx-auto p-8 pt-12 space-y-12 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    How Our Sage 50 Hosting Works
                </h2>

                <div className="relative">
                    <div className="absolute left-1/2 top-0 transform -translate-x-1/2 h-full border-l-2 border-dashed border-gray-300" />
                    <div className="space-y-8">
                        {[
                            {
                                step: 'Consultation',
                                description: 'Speak with our team to discuss your business requirements. We’ll assess your needs and help you choose the best hosting plan for your Sage 50 solution.',
                                icon: <FaComments className="w-10 h-10 text-blue-500" />,
                            },
                            {
                                step: 'Migration',
                                description: 'Our team will handle the entire migration process, ensuring a seamless transition of your Sage 50 data to our secure cloud servers.',
                                icon: <FaExchangeAlt className="w-10 h-10 text-green-500" />,
                            },
                            {
                                step: 'Cloud Access',
                                description: 'Once your Sage 50 is hosted on our platform, you’ll enjoy remote access to your software from any device, anywhere, with full functionality and seamless performance.',
                                icon: <FaCloud className="w-10 h-10 text-purple-500" />,
                            },
                            {
                                step: 'Ongoing Support',
                                description: 'We provide continuous monitoring, daily backups, and full technical support to keep your Sage 50 software running smoothly in the cloud..',
                                icon: <FaHeadset className="w-10 h-10 text-red-500" />,
                            },
                        ].map((step, index) => (
                            <div key={index} className="relative bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center space-y-4">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 flex items-center justify-center bg-white rounded-full border-4 border-gray-200">
                                    {step.icon}
                                </div>
                                <div className="mt-12">
                                    <h3 className="text-2xl font-semibold text-gray-800">{step.step}</h3>
                                    <p className="text-lg text-gray-600 mt-2">{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        );
    };


    const WhyChooseConciseNext = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const cardVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.6,
                    ease: "easeOut",
                    when: "beforeChildren",
                    staggerChildren: 0.3,
                },
            },
        };

        const iconVariants = {
            hidden: { scale: 0 },
            visible: {
                scale: 1,
                transition: {
                    duration: 0.5,
                    ease: "backOut",
                },
            },
        };

        return (
            <div className="bg-gray-50 py-8">
                <div className="max-w-7xl mx-auto px-6 sm:px-8">
                    <motion.h2
                        className="text-4xl font-bold text-center text-gray-800 mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Why Businesses Choose ConciseNext for Sage 50 Hosting
                    </motion.h2>

                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={cardVariants}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8"
                    >
                        {[
                            {
                                title: "Expertise and Experience",
                                description:
                                    "We specialize in providing hosting solutions for accounting software like Sage 50, ensuring a deep understanding of your needs and software requirements.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-blue-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaExpand size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Custom Hosting Plans",
                                description:
                                    "We offer customizable hosting plans designed to meet your specific business size and usage, allowing you to scale your resources as your business grows.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-green-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaPlaystation size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Comprehensive Security",
                                description:
                                    "With ConciseNext’s Sage 50 hosting, your financial data is safe in our highly secure data centers, backed by encrypted connections and proactive threat monitoring.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaShieldAlt size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Affordable Pricing",
                                description:
                                    "Enjoy competitive pricing without compromising on performance or features. Our Sage 50 hosting plans are designed to deliver maximum value while keeping your costs under control.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaDollarSign size={48} />
                                    </motion.div>
                                ),
                            },
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-lg shadow-lg text-start flex flex-col items-center"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    };

    const Sage50HostingPlans = () => {
        // Variants for scroll animation
        const variants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
        };

        return (
            <div className="max-w-7xl mx-auto p-7">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
                    Sage 50 Hosting Plans We Offer
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Single-User Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Single-User Sage 50 Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            Ideal for small businesses, this plan allows one user to access the Sage 50 software in the cloud with full features and flexibility.
                        </p>
                        <a
                            href="#"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Learn More
                        </a>
                    </motion.div>
                    {/* Multi-User Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Multi-User Sage 50 Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            Perfect for growing businesses, this plan supports multiple users collaborating on Sage 50 in real-time, ensuring team-wide access and productivity.
                        </p>
                        <a
                            href="#"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Learn More
                        </a>
                    </motion.div>
                    {/* Custom Plan */}
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.3 }}
                        variants={variants}
                        transition={{ duration: 0.7, ease: 'easeOut' }}
                        className="bg-white border border-gray-200 rounded-lg shadow-lg p-6 flex flex-col items-center text-center transition-transform transform hover:scale-105 hover:shadow-xl"
                    >
                        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Custom Sage 50 Hosting</h3>
                        <p className="text-gray-600 mb-6">
                            If you have unique requirements, we’ll work with you to create a custom hosting solution that meets your business’s specific needs and scale.
                        </p>
                        <a
                            href="#"
                            className="bg-button text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300"
                        >
                            Learn More
                        </a>
                    </motion.div>
                </div>
            </div>
        );
    };


    const ContactUs = () => {
        return (
            <section className="relative bg-gray-100 py-8 px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="absolute inset-0">
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 translate-y-1/4 -z-10" width="404" height="404" fill="none" viewBox="0 0 404 404">
                        <defs>
                            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-indigo-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#pattern1)" />
                    </svg>
                </div>
                <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <motion.h2
                        className="text-3xl font-extrabold text-gray-900 mb-6"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        Ready to Move to the Cloud?
                    </motion.h2>
                    <motion.p
                        className="text-lg text-gray-600 mb-8"
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut', delay: 0.2 }}
                    >
                        Take advantage of the flexibility, security, and cost savings of Sage 50 cloud hosting with ConciseNext. Let us handle the technical details while you focus on growing your business.
                    </motion.p>
                    <div className="flex flex-col items-center">
                        <motion.div
                            className="text-gray-800 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
                        >
                            <p className="text-lg font-semibold">📞 Call us: +1 (800) 123-4567</p>
                            <p className="text-lg font-semibold">📧 Email us: <a href="mailto:sales@ConciseNext.com" className="text-indigo-600 hover:underline">sales@ConciseNext.com</a></p>
                        </motion.div>
                        <motion.p
                            className="text-gray-500"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
                        >
                            ConciseNext – Your trusted partner for reliable and secure Sage 50 hosting solutions.
                        </motion.p>
                    </div>
                </div>
            </section>
        );
    };





    return (
        <div>
            <SageHero />
            <SageIntroSection />
            <SageBenefits />
            <SageHostingWorks />
            <WhyChooseConciseNext />
            <GetStartedSection />
            <Sage50HostingPlans />
            <ContactUs />

           
        </div>
    )

}