import React, { useState } from "react";
// import "./App.css"; // Import Tailwind here

const Price = () => {
  const [isShared, setIsShared] = useState(true);
  const [selectedSoftware, setSelectedSoftware] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [userCount, setUserCount] = useState(1);

  // Pricing for different plans
  const pricing = {
    "Quickbooks Pro": 33,
    "Quickbooks Premier": 45,
    "Quickbooks Enterprise": 60,
    "Quickbooks POS": 50,
    "Drake Hosting": 40,
    "ProSeries Hosting": 35,
    "UltraTax Hosting": 42,
    "Lacerte Hosting": 48,
    "TaxWise Hosting": 38,
    "Sage 50 Hosting": 39,
    "Sage 100 Hosting": 55,
    "Sage 300 Hosting": 60,
    "Sage 500 Hosting": 70,
  };

  // Software Options
  const softwareOptions = {
    Quickbooks: [
      "Quickbooks Pro",
      "Quickbooks Premier",
      "Quickbooks Enterprise",
      "Quickbooks POS",
    ],
    TaxSoftware: [
      "Drake Hosting",
      "ProSeries Hosting",
      "UltraTax Hosting",
      "Lacerte Hosting",
      "TaxWise Hosting",
    ],
    Sage: [
      "Sage 50 Hosting",
      "Sage 100 Hosting",
      "Sage 300 Hosting",
      "Sage 500 Hosting",
    ],
  };

  // Toggle between Shared and Dedicated plans
  const togglePlan = () => {
    setIsShared(!isShared);
    setSelectedOption(""); // Reset selection when switching plans
  };

  // Handle user count change
  const handleUserCount = (type) => {
    if (type === "increase") setUserCount(userCount + 1);
    else if (type === "decrease" && userCount > 1) setUserCount(userCount - 1);
  };

  // Calculate prices based on user count
  const calculatePrice = () => {
    return selectedOption ? pricing[selectedOption] * userCount : 0;
  };

  const handleSoftwareChange = (software) => {
    setSelectedSoftware(software);
    setSelectedOption(""); // Reset option when changing software
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 flex justify-center mt-[120px]">
      <div className="max-w-7xl w-full bg-white shadow-md rounded-lg overflow-hidden flex">
        {/* Sidebar for Software Selection */}
        <div className="w-1/3 p-6 bg-gray-50">
          <div className="flex justify-between items-center mb-4">
            <button
              className={`px-4 py-2 rounded-l-lg ${isShared ? "bg-blue-500 text-white" : "bg-gray-200"
                }`}
              onClick={togglePlan}
            >
              Shared Plan
            </button>
            <button
              className={`px-4 py-2 rounded-r-lg ${!isShared ? "bg-blue-500 text-white" : "bg-gray-200"
                }`}
              onClick={togglePlan}
            >
              Dedicated Plan
            </button>
          </div>

          {/* Quickbooks Dropdown */}
          <div className="mb-6">
            <h3 className="font-bold mb-2">QuickBooks</h3>
            <ul className="space-y-2">
              {softwareOptions.Quickbooks.map((option) => (
                <li key={option}>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="quickbooks"
                      className="mr-2"
                      disabled={isShared} // Disable for Shared Plan
                      checked={selectedOption === option}
                      onChange={() => handleOptionChange(option)}
                    />
                    {option}
                  </label>
                </li>
              ))}
            </ul>
          </div>

          {/* Tax Software Dropdown */}
          <div className="mb-6">
            <h3 className="font-bold mb-2">Tax Software</h3>
            <ul className="space-y-2">
              {softwareOptions.TaxSoftware.map((option) => (
                <li key={option}>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="taxsoftware"
                      className="mr-2"
                      disabled={isShared}
                      checked={selectedOption === option}
                      onChange={() => handleOptionChange(option)}
                    />
                    {option}
                  </label>
                </li>
              ))}
            </ul>
          </div>

          {/* Sage Dropdown */}
          <div>
            <h3 className="font-bold mb-2">Sage</h3>
            <ul className="space-y-2">
              {softwareOptions.Sage.map((option) => (
                <li key={option}>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="sage"
                      className="mr-2"
                      disabled={isShared}
                      checked={selectedOption === option}
                      onChange={() => handleOptionChange(option)}
                    />
                    {option}
                  </label>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Right Side Cart */}
        <div className="w-2/3 p-6">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">
              {selectedOption || "Select an option"}
            </h2>
            <div className="flex items-center">
              <button
                onClick={() => handleUserCount("decrease")}
                className="bg-gray-300 px-2 py-1"
              >
                -
              </button>
              <span className="px-4">{userCount}</span>
              <button
                onClick={() => handleUserCount("increase")}
                className="bg-gray-300 px-2 py-1"
              >
                +
              </button>
            </div>
          </div>

          {/* Cart Details */}
          <div className="mt-4">
            <p className="text-lg">
              <strong>Storage:</strong> 5GB
            </p>
            <p className="text-lg">
              <strong>Users:</strong> {userCount}
            </p>
            <p className="text-lg">
              <strong>Monthly Price:</strong> ${calculatePrice().toFixed(2)}
            </p>
            <p className="text-sm text-gray-600">
              Half-yearly Price:{" "}
              <span className="line-through">
                ${(calculatePrice() * 6).toFixed(2)}
              </span>{" "}
              ${(calculatePrice() * 6 * 0.95).toFixed(2)} (5% Off)
            </p>
            <p className="text-sm text-gray-600">
              Annual Price:{" "}
              <span className="line-through">
                ${(calculatePrice() * 12).toFixed(2)}
              </span>{" "}
              ${(calculatePrice() * 12 * 0.9).toFixed(2)} (10% Off)
            </p>
          </div>

          {/* CTA Buttons */}
          <div className="mt-6 flex">
            <button className="bg-blue-500 text-white px-4 py-2 rounded-lg">
              Request A Free Quote
            </button>
            <button className="ml-4 px-4 py-2 text-blue-500 border border-blue-500 rounded-lg">
              Contact Sales
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
