import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import tax from "../../assets/images/tax.webp"

const TaxDropdown = () => {
    return (
        <div className="flex justify-center">
            <FlyoutLink FlyoutContent={PricingContent}>
                Tax Software
            </FlyoutLink>
        </div>
    );
};

const FlyoutLink = ({ children, href, FlyoutContent }) => {
    const [open, setOpen] = useState(false);

    const showFlyout = FlyoutContent && open;

    return (
        <div
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            className="relative w-fit h-fit"
        >
            <a href={href} className="relative text-white text-pretty">
                {children}
                <span
                    style={{
                        transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
                    }}
                    className="absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-indigo-300 transition-transform duration-300 ease-out"
                />
            </a>
            <AnimatePresence>
                {showFlyout && (
                    <motion.div
                        initial={{ opacity: 0, y: 15 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 15 }}
                        style={{ translateX: "-50%" }}
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        className="absolute left-1/2 top-12 bg-white text-black"
                    >
                        <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
                        <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" />
                        <FlyoutContent />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const PricingContent = () => {
    return (
        <div className="w-128 sub-menu h-auto flex flex-row justify-between items-center bg-white p-6 shadow-xl">
            <div className="mx-2 space-y-5">
                <Link to={"/tax-hosting"} className="block">Tax Software Hosting</Link>
                <Link to={"/tax-drake-hosting"} className="block ">Drake Tax Software Hosting</Link>
                <Link to={"/ultra-tax-cs"} className="block ">UltraTax CS Hosting</Link>
                <Link to={"/lacerte-hosting"} className="block">Lacerte Hosting</Link>
                <Link to={"/pro-series-hosting"} className="block  ">ProSeries Tax Software Hosting</Link>
                <Link to={"/tax-wise-hosting"} className="block  ">TaxWise Hosting</Link>
            </div>
            <div><img src={tax} height="150" width="150" /></div>
        </div>
    );
};

export default TaxDropdown;