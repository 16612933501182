import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTools, FaExchangeAlt, FaHandshake, FaCloud, FaComments, FaDatabase, FaServer, FaHeadset, FaCogs, FaUserCheck, FaDollarSign, FaUserClock, FaLayerGroup, FaShieldAlt, } from 'react-icons/fa';





export default function SageHosting() {

    const SageHero = () => {
        const { scrollY } = useScroll();
        const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
        const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
        const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
        const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

        return (
            <div className="relative h-[500px] md:h-[600px] bg-[url('https://www.swktech.com/wp-content/uploads/2024/04/sage-100-upgrade-cloud-hosting.webp')] bg-center bg-no-repeat bg-cover overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Sage Hosting With <span className='text-blue-600 font-bold'>ConciseNext</span>
                    </motion.h1>
                    <motion.p
                        className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        Unlock the power of Sage with ConciseNext's secure and reliable cloud hosting solutions. Access your Sage software from anywhere, anytime, with unparalleled flexibility and support.
                    </motion.p>
                    <motion.a
                        href="#"
                        className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
                    >
                        Learn More
                    </motion.a>
                </div>

                {/* Parallax Image */}
                <motion.img
                    src="https://www.clarity-ventures.com/images/clarity-connect-integration-platform.png"
                    alt="QuickBooks"
                    className="absolute bottom-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 right-0 transform translate-y-8 md:translate-y-16 sm:translate-y-0"
                    style={{ y: yImage }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                />
            </div>
        );
    };



    const SageIntroSection = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView && window.innerWidth > 768) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Sage Hosting Services - ConciseNext
                </h2>

                <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
                    <img
                        src="https://www.clarity-ventures.com/images/integrations/img69.png"
                        alt="QuickBooks License"
                        className="rounded-lg shadow-lg w-[400px] h-[auto]"
                    />
                    <p className="text-lg text-gray-700 leading-relaxed text-center md:text-left">
                        Experience the power and flexibility of cloud-hosted Sage software with ConciseNext’s Sage Hosting Services. We specialize in delivering secure, reliable, and cost-effective Sage cloud hosting solutions that allow you to access your accounting and business management software from anywhere, at any time.
                    </p>
                </div>

                <h3 className="text-3xl pt-8 font-bold text-gray-800 text-center md:text-left">
                    Why Choose ConciseNext for Sage Hosting?
                </h3>

                <ul className="space-y-8">
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaUserCheck className="w-8 h-8 text-blue-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cloud Accessibility</h4>
                            <p className="text-lg text-gray-700">
                                Our Sage hosting allows you to run Sage applications like Sage 50, Sage 100, and Sage 300 in the cloud. You can access your software and data from any location, on any device, without the limitations of local hardware.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaDollarSign className="w-8 h-8 text-green-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cost-Efficient</h4>
                            <p className="text-lg text-gray-700">
                                Avoid the high costs of managing and maintaining on-site servers. Our Sage cloud hosting offers a pay-as-you-go model, allowing you to scale your resources as needed while keeping your expenses under control.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaTools className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Seamless Collaboration</h4>
                            <p className="text-lg text-gray-700">
                                Hosting Sage in the cloud ensures your team can work together seamlessly, whether in the office or remotely. Multi-user access lets your team collaborate on accounting tasks in real-time without data sync issues.
                            </p>
                        </div>
                    </li>

                </ul>
            </motion.div>
        );
    };

    const SageBenefits = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto pt-8 p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Benefits of Hosting Sage with ConciseNext
                </h2>

                {/* License Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
                    {[
                        {
                            title: 'Advanced Security',
                            description:
                                'Data security is our top priority. Our hosting platform ensures your Sage data is protected with end-to-end encryption, multi-layered firewalls, and regular backups.',
                            imageUrl:
                                'https://imageio.forbes.com/specials-images/imageserve/64c28d7071424270e316a7fe/Data-protection-privacy-concept--Business-man-protecting-his-personal-data-/960x0.jpg?format=jpg&width=960',
                            link: '/quickbooks-pro-hosting',
                        },
                        {
                            title: 'Optimized Performance',
                            description:
                                'Experience faster speeds and enhanced reliability with our high-performance servers designed specifically for Sage applications.',
                            imageUrl:
                                'https://www.executivegrapevine.com/uploads/articles/hr-learn20-gpstrategies-thumb.jpg',
                            link: '#',
                        },

                        {
                            title: '24/7 Expert Support',
                            description:
                                'Our technical support team is available around the clock to ensure your Sage hosting runs smoothly. Whether it is setup assistance or troubleshooting, we’re here to help.',
                            imageUrl:
                                'https://www.voipreview.org/sites/voipreview.org/files/styles/blog_post/public/blog/featuredt_images/2019/03/best-online-tech-support-services.jpg?itok=bkbqdkFd',
                            link: '#',
                        },
                        {
                            title: 'Fully Managed Hosting',
                            description:
                                'Let us handle all the technical details. Our Sage hosting services include server management, software updates, and regular data backups, so you can focus on running your business.',
                            imageUrl:
                                'https://www.gep.com/prod/s3fs-public/blog-images/technology-driven-workplace-transformation-and-how-to-go-about-it.jpg',
                            link: '#',
                        },
                    ].map((license, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            className="bg-white rounded-lg shadow-lg p-5 flex flex-col items-center text-start space-y-4 transition-transform duration-300"
                        >
                            <img
                                src={license.imageUrl}
                                alt={license.title}
                                className="rounded-lg shadow-lg w-full h-48 object-cover"
                            />
                            <h4 className="text-xl font-semibold text-gray-800">
                                {license.title}
                            </h4>
                            <p className="text-gray-600 pb-10">{license.description}</p>

                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };


    const SageHostingSolutions = () => {
        const controls = useAnimation();
        const { ref, inView } = useInView({ threshold: 0.2 });

        React.useEffect(() => {
            if (inView) {
                controls.start('visible');
            } else {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const fadeInVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
        };
        const isLargeScreen = window.innerWidth >= 768;

        return (
            <div className="bg-gray-100 text-gray-800 py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold text-center mb-12">Sage Hosting Solutions We Offer</h2>

                    <div ref={ref} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-blue-500"
                            initial="hidden"
                            animate={isLargeScreen ? controls : 'visible'}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaCloud className="text-blue-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Sage 50 Cloud Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Streamline your accounting with our Sage 50 hosting solution. Manage your books from anywhere, and ensure real-time data access and collaboration for your team.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 hover:bg-blue-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-green-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaServer className="text-green-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Sage 100 Cloud Hosting</h3>
                            </div>
                            <p className="text-lg mb-6 h-28  overflow-auto">
                                If your business needs advanced ERP features, our Sage 100 cloud hosting provides the flexibility and performance required to manage everything from accounting to inventory control, without the limitations of on-premises infrastructure.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-md hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-red-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaDatabase className="text-red-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Sage 300 Cloud Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                For businesses looking to scale and manage complex operations, our Sage 300 hosting offers a robust platform for financial management, operations, and inventory control.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-lg hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>
                    </div>
                </div>
            </div>
        );
    };



    const SageHostingWorks = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-6xl mx-auto p-8 pt-8 space-y-12 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    How Sage Hosting Works
                </h2>

                <div className="relative">
                    <div className="absolute left-1/2 top-0 transform -translate-x-1/2 h-full border-l-2 border-dashed border-gray-300" />
                    <div className="space-y-8">
                        {[
                            {
                                step: 'Consultation',
                                description: 'Reach out to us, and we’ll help you choose the best Sage hosting solution for your business based on your requirements.',
                                icon: <FaComments className="w-10 h-10 text-blue-500" />,
                            },
                            {
                                step: 'Migration',
                                description: 'Our team will handle the migration of your data and applications to the cloud. We ensure a smooth transition with minimal disruption to your daily operations.',
                                icon: <FaExchangeAlt className="w-10 h-10 text-green-500" />,
                            },
                            {
                                step: 'Hosting & Access',
                                description: 'Once your Sage software is hosted on our cloud platform, you’ll gain secure, remote access to your software, ensuring you’re always connected to your financial data.',
                                icon: <FaCloud className="w-10 h-10 text-purple-500" />,
                            },
                            {
                                step: 'Ongoing Support',
                                description: 'Our team provides ongoing maintenance and technical support, including software updates and daily backups, to keep your system running efficiently.',
                                icon: <FaHeadset className="w-10 h-10 text-red-500" />,
                            },
                        ].map((step, index) => (
                            <div key={index} className="relative bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center space-y-4">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 flex items-center justify-center bg-white rounded-full border-4 border-gray-200">
                                    {step.icon}
                                </div>
                                <div className="mt-12">
                                    <h3 className="text-2xl font-semibold text-gray-800">{step.step}</h3>
                                    <p className="text-lg text-gray-600 mt-2">{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        );
    };


    const WhyChooseConciseNext = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const cardVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.6,
                    ease: "easeOut",
                    when: "beforeChildren",
                    staggerChildren: 0.3,
                },
            },
        };

        const iconVariants = {
            hidden: { scale: 0 },
            visible: {
                scale: 1,
                transition: {
                    duration: 0.5,
                    ease: "backOut",
                },
            },
        };

        return (
            <div className="bg-gray-50 py-5">
                <div className="max-w-7xl mx-auto px-6 sm:px-8">
                    <motion.h2
                        className="text-4xl font-bold text-center text-gray-800 mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Why Choose ConciseNext?
                    </motion.h2>

                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={cardVariants}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
                    >
                        {[
                            {
                                title: "Industry-Leading Security",
                                description:
                                    "Your data’s security is our top priority. With daily backups, encrypted connections, and advanced firewalls, your information is always safe.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-blue-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaShieldAlt size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Scalable Solutions",
                                description:
                                    "Whether you’re a small business or a growing enterprise, our Sage hosting can scale with your needs.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-green-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaLayerGroup size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Hassle-Free IT Management",
                                description:
                                    "Focus on your business while we handle all the technical aspects of managing your Sage software and cloud infrastructure.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaTools size={48} />
                                    </motion.div>
                                ),
                            },
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-lg shadow-lg text-start flex flex-col items-center"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    };

    const ContactUs = () => {
        return (
            <section className="relative bg-gray-100 py-6 px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="absolute inset-0">
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 translate-y-1/4 -z-10" width="404" height="404" fill="none" viewBox="0 0 404 404">
                        <defs>
                            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-indigo-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#pattern1)" />
                    </svg>
                </div>
                <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <motion.h2
                        className="text-3xl font-extrabold text-gray-900 mb-6"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        Contact Us
                    </motion.h2>
                    <motion.p
                        className="text-lg text-gray-600 mb-8"
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut', delay: 0.2 }}
                    >
                        Ready to take your Sage software to the cloud? Contact ConciseNext for personalized Sage hosting solutions that deliver performance, security, and peace of mind.
                    </motion.p>
                    <div className="flex flex-col items-center">
                        <motion.div
                            className="text-gray-800 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
                        >
                            <p className="text-lg font-semibold">📞 Call us: +1 (800) 123-4567</p>
                            <p className="text-lg font-semibold">📧 Email us: <a href="mailto:sales@ConciseNext.com" className="text-indigo-600 hover:underline">sales@ConciseNext.com</a></p>
                        </motion.div>
                        <motion.p
                            className="text-gray-500"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
                        >
                            ConciseNext – Elevating your business with cloud-hosted Sage solutions
                        </motion.p>
                    </div>
                </div>
            </section>
        );
    };




    return (
        <div>
            <SageHero />
            <SageIntroSection />
            <SageBenefits />
            <SageHostingSolutions />
            <SageHostingWorks />
            <WhyChooseConciseNext />
            <ContactUs />

           
        </div>
    )

}