import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "../../styles/navbar.css"; // Import custom styles for animations
import QuickbooksDropdown from "./QuickbooksDropdown";
import OtherDropdown from "./OtherDropdown";
import SageDropdown from "./SageDrodown";
import logo from "./../../assets/images/logo/logoi.png";
import TaxDropdown from "./TaxDropdown";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (dropdown) => {
    if (isDropdownOpen === dropdown) {
      setIsDropdownOpen(null);
    } else {
      setIsDropdownOpen(dropdown);
    }
  };

  return (
    <nav className="bg-bgtrans fixed xl:absolute top-0 left-0 right-0 z-20 w-full">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center xl:items-end justify-between h-16 xl:h-24">
          <Link to={"/"}>
            <img src={logo} className=" h-12 xl:h-20 w-auto rounded-xl" alt="ConciseNext Logo" />
          </Link>

          <div className="hidden xl:flex space-x-7 items-center menu-item">
            <Link to={"/"} className="text-lg text-white">Home</Link>
            <QuickbooksDropdown />
            <SageDropdown />
            <TaxDropdown />
            <OtherDropdown />
            <Link to="/contact-us" className="text-lg text-white">
              Contact Us
            </Link>
            <Link className="text-lg text-white" to={"/price"}>
              Pricing
            </Link>
          </div>

          <div className="flex xl:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition duration-500 ease-in-out"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <CSSTransition in={isOpen} timeout={300} classNames="fade" unmountOnExit>
        <div className="xl:hidden bg-slate-800 w-full">
          <Link onClick={() => setIsOpen(false)} to={"/"} className="block px-3 py-2 rounded-md text-lg text-white font-medium hover:bg-slate-700 transition duration-300 ease-in-out">Home</Link>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button
              onClick={() => toggleDropdown("quickbooks")}
              className="text-white hover:bg-slate-700 block px-3 py-2 rounded-md text-base font-bold w-full text-left transition duration-300 ease-in-out"
            >
              QuickBooks Solutions
            </button>
            <CSSTransition
              in={isDropdownOpen === "quickbooks"}
              timeout={200}
              classNames="dropdown"
              unmountOnExit
            >
              <div className="space-y-1 pl-4">
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/quickbooks-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  QuickBooks Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/quickbooks-pro-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  QuickBooks Pro Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/quickbooks-premier-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  QuickBooks Premier Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/quickbooks-enterprise-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  QuickBooks Enterprise Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/quickbooks-pos-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  QuickBooks POS Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/quickbooks-add-ons-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  QuickBooks Add-ons Hosting
                </Link>
                {/* <Link
                  onClick={() => setIsOpen(false)}
                  to="/buy-quickbooks"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Buy QuickBooks
                </Link> */}
              </div>
            </CSSTransition>
          </div>

          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button
              onClick={() => toggleDropdown("sage")}
              className="text-white hover:bg-slate-700 block px-3 py-2 rounded-md text-base font-bold w-full text-left transition duration-300 ease-in-out"
            >
              Sage Applications
            </button>
            <CSSTransition
              in={isDropdownOpen === "sage"}
              timeout={200}
              classNames="dropdown"
              unmountOnExit
            >
              <div className="space-y-1 pl-4">
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/sage-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Sage Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/sage-50-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Sage 50 Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/sage-100-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Sage 100 ERP Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/sage-300-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Sage 300 ERP Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/sage-500-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Sage 500 ERP Hosting
                </Link>
              </div>
            </CSSTransition>
          </div>

          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button
              onClick={() => toggleDropdown("tax")}
              className="text-white hover:bg-slate-700 block px-3 py-2 rounded-md text-base font-bold w-full text-left transition duration-300 ease-in-out"
            >
              Tax Software
            </button>
            <CSSTransition
              in={isDropdownOpen === "tax"}
              timeout={200}
              classNames="dropdown"
              unmountOnExit
            >
              <div className="space-y-1 pl-4">
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/tax-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Tax Software Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/tax-drake-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Drake Tax Software Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/lacerte-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Lacerte Tax Software Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/ultra-tax-cs"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  UltraTax Software Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/pro-series-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  ProSeries Tax Software Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/tax-wise-hosting"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  TaxWise Hosting
                </Link>

              </div>
            </CSSTransition>
          </div>

          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button
              onClick={() => toggleDropdown("other")}
              className="text-white hover:bg-slate-700 block px-3 py-2 rounded-md text-base font-bold w-full text-left transition duration-300 ease-in-out"
            >
              Other Services
            </button>
            <CSSTransition
              in={isDropdownOpen === "other"}
              timeout={200}
              classNames="dropdown"
              unmountOnExit
            >
              <div className="space-y-1 pl-4">
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/small-business"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Small Business
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/law-firm"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Law Firm Hosting
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/virtual-desktop"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Virtual Desktop
                </Link>
                <Link
                  onClick={() => setIsOpen(false)}
                  to="/managed-it-services"
                  className="block px-4 py-2 text-sm text-white hover:bg-slate-700 transition duration-300 ease-in-out"
                >
                  Managed IT Services
                </Link>

              </div>
            </CSSTransition>
          </div>

          <Link
            onClick={() => setIsOpen(false)}
            to="/contact-us"
            className="block px-3 py-2 rounded-md text-lg text-white font-medium hover:bg-slate-700 transition duration-300 ease-in-out"
          >
            Contact Us
          </Link>
        </div>
      </CSSTransition>
      <div className="hidden lg:flex justify-end items-center px-8 mt-4">
        <a href="tel:+91123123123" className="calling-phone text-white">
          <b>&#9742; &nbsp; X-XXX-XXXXXXX</b>
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
