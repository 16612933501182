import React, { useEffect, useRef } from 'react';
import { ShieldCheckIcon, GlobeAltIcon, ClockIcon, ChartBarIcon, SupportIcon, CloudIcon } from '@heroicons/react/solid';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import dataSec from "../assets/images/datasecurity.webp";
import remote from "../assets/images/remotAccess.webp";
import uptime from "../assets/images/uptime.webp";
import scalable from "../assets/images/scalable.webp";
import support from "../assets/images/Support.webp";
import qbHosting from "../assets/images/qbHosting.webp";
import qbHeroImg from "../assets/images/qbHeroImg.webp";
import accessibility from "../assets/images/Accessibility.webp"
import premier from "../assets/images/qb/premier.webp"
import qbpre from "../assets/images/quickbookpre.webp"
import multiUsr from "../assets/images/multiUsr.webp"
import accdata from "../assets/images/products.svg"
// import qbpre from "../assets/images/quickbookspremier.webp"
import dataBckp from "../assets/images/dataBackup.webp"
import { FaUser, FaUsers, FaHdd, FaMemory, FaAppStore } from 'react-icons/fa';

const features = [
  {
    id: 1,
    name: 'Access Your Data Anytime, Anywhere',
    icon: ShieldCheckIcon,
    description: 'ConciseNext’s QuickBooks Premier hosting enables you to manage your finances from any location, on any device. Whether in the office, at home, or on the move, you can easily access your data, ensuring that your financial management is always within reach.',
    image: accdata,
  },
  {
    id: 2,
    name: 'Enhanced Security for Peace of Mind',
    icon: GlobeAltIcon,
    description: 'Security is a top priority at ConciseNext. We implement state-of-the-art security measures, including encryption, multi-layered authentication, and secure data centers, to protect your financial data from threats and unauthorized access.',
    image: dataSec,
  },
  {
    id: 3,
    name: 'Uninterrupted Performance and Reliability',
    icon: ClockIcon,
    description: 'With ConciseNext’s 99.9% uptime guarantee, your QuickBooks Premier software remains operational whenever you need it. Our reliable hosting infrastructure ensures smooth performance, reducing the risk of downtime that could disrupt your business..',
    image: uptime,
  },
  {
    id: 4,
    name: 'Collaboration Made Simple',
    icon: ChartBarIcon,
    description: 'Empower your team to work together more efficiently with ConciseNext’s cloud-hosted QuickBooks Premier. Multiple users can simultaneously access and work on the same files, fostering better collaboration and more streamlined financial processes.',
    image: scalable,
  },
  {
    id: 9,
    name: '24/7 Dedicated Support',
    icon: SupportIcon,
    description: 'Our experienced support team is available around the clock to assist with any technical issues or questions. With ConciseNext, you’ll have access to knowledgeable QuickBooks Premier experts whenever you need them, ensuring your operations run smoothly.',
    image: support,
  },
  {
    id: 8,
    name: 'Scalable and Cost-Effective Hosting',
    icon: CloudIcon,
    description: 'As your business grows, so do your hosting needs. ConciseNext offers scalable hosting solutions that can expand alongside your business, all while remaining cost-effective. Save on IT costs by eliminating the need for on-premise servers and maintenance.',
    image: qbHosting,
  },
];





const BenefitsSection = () => {
  const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
  const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
  const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });

  return (
    <div className="bg-white py-6">
      <div className="container mx-auto px-6">
        <h2 className={`text-3xl font-bold text-center mb-6 ${inView1 ? 'animate-fadeIn' : ''}`}>
          Key Benefits of QuickBooks Hosting with ConciseNext
        </h2>
        <div className="grid md:grid-cols-3 gap-7">
          <div
            ref={ref1}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView1 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Complete Accessibility</h3>
            <p className="mb-4 card-info">
              Access your QuickBooks software from any device, anytime, anywhere. Whether you’re on your laptop, tablet, or smartphone, ConciseNext ensures you’re always connected to your data.
            </p>
            <img
              src={accessibility}
              alt="Accessibility"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref2}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView2 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Data Backup & Recovery</h3>
            <p className="mb-4 card-info">
              We provide automated data backups and quick recovery options to ensure your financial data is always secure, even in the event of unexpected issues.
            </p>
            <img
              src={dataBckp}
              alt="Backup and Recovery"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
          <div
            ref={ref3}
            className={`bg-gray-100 p-8 rounded-lg shadow-lg transition-transform duration-500 ${inView3 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          >
            <h3 className="text-2xl text-[#3636b9] font-semibold mb-4">Multi-User Collaboration</h3>
            <p className="mb-4 card-info">
              Collaborate with your team in real-time. With QuickBooks hosting by ConciseNext, multiple users can access and work on the same files simultaneously.
            </p>
            <img
              src={multiUsr}
              alt="Collaboration"
              className="rounded-lg shadow-lg hover:shadow-xl transition duration-500"
              style={{ maxWidth: '100%', height: '300px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const HostingSection = () => {
  return (
    <div className="bg-gray-100 py-6 px-4 md:py-9 md:px-6">
      {/* Container */}
      <div className="container mx-auto max-w-7xl">
        {/* Header Section */}
        <div className="text-center mb-4 md:mb-16">
          <h1 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-3 md:mb-4">
            QuickBooks Premier Hosting by ConciseNext
          </h1>
          <p className="text-base md:text-lg text-gray-600">
            Secure, Reliable, and Scalable Cloud Hosting Solutions
          </p>
        </div>
        {/* Content Section */}
        <div className="grid gap-8 md:gap-16 items-center grid-cols-1 md:grid-cols-2">
          {/* Text Content */}
          <div className="space-y-4 md:space-y-6">
            <p className="text-base md:text-xl text-gray-700 leading-relaxed">
              ConciseNext provides top-tier QuickBooks Premier hosting services that cater to the diverse needs of businesses, accountants, and financial professionals. By moving your QuickBooks Premier to the cloud with ConciseNext, you gain unparalleled access, security, and reliability for your financial operations.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="flex items-center space-x-2">
                <ShieldCheckIcon className="h-6 w-6 md:h-8 md:w-8 text-blue-600" />
                <span className="font-semibold text-gray-800">Secure</span>
              </div>
              <div className="flex items-center space-x-2">
                <GlobeAltIcon className="h-6 w-6 md:h-8 md:w-8 text-green-600" />
                <span className="font-semibold text-gray-800">Reliable</span>
              </div>
              <div className="flex items-center space-x-2">
                <CloudIcon className="h-6 w-6 md:h-8 md:w-8 text-purple-600" />
                <span className="font-semibold text-gray-800">Scalable</span>
              </div>
            </div>
          </div>
          {/* Image */}
          <div className="relative">
            <img
              src={premier}
              alt="Cloud Hosting"
              className="rounded-lg shadow-lg transform hover:scale-105 transition duration-500 h-60 md:h-80"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuickBooksProHostingIntro = () => {
  return (
    <section className="bg-gradient-to-r from-blue-800 via-blue-500 to-purple-400 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <motion.h2
          className="text-4xl font-bold mb-6"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          QuickBooks Premier Hosting Pricing & Plans
        </motion.h2>
        <motion.p
          className="text-lg leading-relaxed max-w-2xl mx-auto"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          At <span className="font-bold">ConciseNext</span>, we offer flexible hosting plans designed to meet the unique needs of businesses of all sizes. Whether you're an individual user or part of a growing team, our plans provide the performance, security, and scalability you need to keep your accounting operations running smoothly.
        </motion.p>
      </div>
    </section>
  );
};

const StandardHostingPlan = () => {
  const standardPlans = [
    {
      title: '1-User Plan',
      price: '$33',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-purple-100',
    },
    {
      title: 'Per Additional User',
      price: '$30',
      description: 'Per Month',
      storage: '5GB Per User',
      color: 'bg-pink-100',
    }
  ];

  return (
    <section className="py-14 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Standard Hosting Plans</h2>

        <div className="flex flex-wrap justify-center gap-6">
          {standardPlans.map(plan => (
            <motion.div
              key={plan.title}
              className={`${plan.color} p-6 rounded-3xl shadow-lg border border-gray-200 w-full sm:w-[48%] md:w-[30%] lg:w-[18%] h-[250px] flex flex-col justify-between`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.98 }}
              transition={{ duration: 0.3 }}
            >
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-2 text-gray-800">{plan.title}</h3>
                <div className="text-3xl font-bold text-gray-800 mb-1">{plan.price}</div>
                <div className="text-lg text-gray-600 mb-4">{plan.description}</div>
              </div>
              <div className="flex items-center justify-center text-gray-600">
                <FaHdd className="text-blue-600 mr-2" />
                <span>{plan.storage}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const DifferenceSection = () => {
  return (
    <div className="relative py-20 px-6">
      {/* Background Effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-800 via-blue-500 to-purple-300 opacity-1 "></div>
      {/* Content */}
      <div className="relative container mx-auto max-w-5xl text-center text-white">
        <h2 className="text-4xl font-extrabold mb-6">Unlock the Full Potential of QuickBooks Premier with ConciseNext</h2>
        <p className="text-lg leading-relaxed mb-8">
          Choosing ConciseNext for your QuickBooks Premier hosting means partnering with a provider that understands the importance of reliable and secure financial management. With our cloud-hosted solutions, you can focus on growing your business while we handle the technical aspects.
        </p>
        <button className="bg-white text-button font-semibold py-2 px-6 rounded-full shadow-lg hover:bg-gray-100 transition transform hover:scale-105">
          Learn More
        </button>
      </div>
    </div>
  );
};

const SmoothScrollFeature = ({ feature, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });

  useEffect(() => {
    if (inView && window.innerWidth > 768) {  // Check if screen is larger than 768px
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={window.innerWidth > 768 ? controls : 'visible'}  // Disable animation on small screens
      variants={{
        hidden: { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
        visible: { opacity: 1, x: 0 },
      }}
      transition={{ duration: 0.7, ease: 'easeOut' }}
      className="grid md:grid-cols-2 gap-8 xl:gap-20 items-center py-8"
    >
      {/* Image */}
      <div className={`${index % 2 === 0 ? 'order-last md:order-first' : ''}`}>
        <img
          src={feature.image}
          alt={feature.name}
          className="rounded-lg shadow-lg transform hover:scale-105 h-[auto] w-[400px] xl:h-[auto] xl:w-[600px] transition duration-500"
        />
      </div>
      {/* Text */}
      <div>
        <h3 className="text-2xl font-bold text-gray-800 mb-4">{feature.name}</h3>
        <p className="text-lg text-gray-700 leading-relaxed">{feature.description}</p>
      </div>
    </motion.div>
  );
};

const Hero = () => {
  const { scrollY } = useScroll();
  const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
  const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
  const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
  const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

  return (
    <div className="relative h-[500px] md:h-[600px]  bg-[url('https://img.pikbest.com/wp/202405/cloud-server-illustration-of-computing-technology-with-3d-and-laptops_9855617.jpg!w700wp')] bg-cover bg-center bg-no-repeat overflow-hidden">
      {/* Background Circles Effect */}
      <div className="absolute flex items-center justify-center">
        <motion.div
          className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
          style={{ scale: scale1 }}
        />
        <motion.div
          className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
          style={{ scale: scale2 }}
        />
        <motion.div
          className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
          style={{ scale: scale3 }}
        />
      </div>

      {/* Hero Content */}
      <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-white text-center px-4 md:px-6">
        <motion.h1
          className="text-3xl sm:text-4xl md:text-3xl font-bold mb-2 md:mb-4"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          Elevate Your Accounting Experience with ConciseNext’s Premier QuickBooks Hosting
        </motion.h1>
        <motion.p
          className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
        >
         Unmatched Security, Reliability, and Scalability for Accounting Experts
        </motion.p>
        <motion.a
          href="#"
          className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
        >
          Learn More
        </motion.a>
      </div>



    </div>
  );
};

const QuickBooksPremierHosting = () => {
  return (
    <div>
      <Hero/>
      <HostingSection />
      <div className="bg-white py-2">
        <div className="container mx-auto max-w-7xl">
          {features.map((feature, index) => (
            <SmoothScrollFeature key={feature.id} feature={feature} index={index} />
          ))}
        </div>
      </div>

      <DifferenceSection />
      <BenefitsSection />
      <QuickBooksProHostingIntro />
      <StandardHostingPlan />
      


    </div>
  );
};

export default QuickBooksPremierHosting;
