import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FaTools, FaExchangeAlt, FaHandshake, FaCloud, FaComments, FaDatabase, FaServer, FaHeadset, FaCogs, FaUserCheck, FaDollarSign, FaUserClock, FaLayerGroup, FaShieldAlt, FaLaptop, FaUserFriends, FaLock, } from 'react-icons/fa';



export default function UltraTaxCS() {

    const TaxHero = () => {
        const { scrollY } = useScroll();
        const scale1 = useTransform(scrollY, [0, 1500], [1, 2]);
        const scale2 = useTransform(scrollY, [0, 1800], [1, 2]);
        const scale3 = useTransform(scrollY, [0, 2100], [1, 2]);
        const yImage = useTransform(scrollY, [0, 1000], [0, 100]);

        return (
            <div className="relative h-[500px] md:h-[600px] bg-[url('https://media.assettype.com/analyticsinsight%2F2024-07%2F6202e5e2-6d31-42a3-8d0e-76a4aaa880c0%2FTechnology_Can_Boost_Your_Business_Productivity.jpg')] bg-center bg-no-repeat bg-cover overflow-hidden">
                {/* Background Circles Effect */}
                <div className="absolute flex items-center justify-center">
                    <motion.div
                        className="w-48 h-48 md:w-80 md:h-80 bg-white opacity-10 rounded-full absolute"
                        style={{ scale: scale1 }}
                    />
                    <motion.div
                        className="w-36 h-36 md:w-60 md:h-60 bg-white opacity-20 rounded-full absolute"
                        style={{ scale: scale2 }}
                    />
                    <motion.div
                        className="w-24 h-24 md:w-40 md:h-40 bg-white opacity-30 rounded-full absolute"
                        style={{ scale: scale3 }}
                    />
                </div>

                {/* Hero Content */}
                <div className="relative bg-bgtrans z-10 flex flex-col items-center justify-center h-full text-gray-100 text-center px-4 md:px-6">
                    <motion.h1
                        className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 md:mb-4"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        UltraTax CS Hosting with <span className='text-blue-600 font-bold'>ConciseNext</span>
                    </motion.h1>
                    <motion.p
                        className="text-sm sm:text-base md:text-lg max-w-md md:max-w-xl mb-4 md:mb-6"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
                    >
                        Enhance your tax preparation with ConciseNext’s UltraTax CS Hosting, providing secure, remote access for seamless collaboration. Our scalable solutions streamline tax filing for professionals and teams alike.
                    </motion.p>
                    <motion.a
                        href="#"
                        className="px-4 py-2 md:px-6 md:py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-lg hover:bg-gray-100 transition-colors duration-300"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 0.8, ease: "easeOut", delay: 0.5 }}
                    >
                        Learn More
                    </motion.a>
                </div>

                {/* Parallax Image */}
                {/* <motion.img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf0J11mGCr4qq2ijT_M-BkcRpwBC9Hw_JKGP3_gcRakZAv1oSAWrooX9aS9uVTUX9gFF4&usqp=CAU"
                    alt="QuickBooks"
                    className="absolute bottom-0 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 right-0 transform translate-y-8 md:translate-y-16 sm:translate-y-0"
                    style={{ y: yImage }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1, ease: "easeOut" }}
                /> */}
            </div>
        );
    };



    const TaxIntroSection = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView && window.innerWidth > 768) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    UltraTax CS Hosting - ConciseNext
                </h2>

                <div className="flex flex-col md:flex-row gap-6 justify-center items-center">
                    <img
                        src="https://www.ijzi.com/wp-content/uploads/2017/07/technology-high-tech.jpg"
                        alt="QuickBooks License"
                        className="rounded-lg shadow-lg w-[400px] h-[auto]"
                    />
                    <p className="text-lg text-gray-700 leading-relaxed text-center md:text-left">
                        Maximize productivity and streamline your tax preparation process with ConciseNext’s UltraTax CS Hosting services. By hosting UltraTax CS in the cloud, you gain the flexibility to access your tax software from anywhere, on any device, while ensuring your sensitive client data is protected by enterprise-grade security. Whether you’re an individual tax professional or a large CPA firm, our hosting solutions provide the scalability, performance, and security needed for efficient tax operations.
                    </p>
                </div>

                <h3 className="text-3xl pt-8 font-bold text-gray-800 text-center md:text-left">
                    Why Choose ConciseNext for UltraTax CS Hosting?
                </h3>

                <ul className="space-y-8">
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaLaptop className="w-8 h-8 text-blue-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Remote Access from Anywhere</h4>
                            <p className="text-lg text-gray-700">
                                Work on tax returns anytime, anywhere, with remote access to UltraTax CS from any internet-enabled device. This flexibility allows you to continue your tax preparation without being tied to your office desktop.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaUserFriends className="w-8 h-8 text-green-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Multi-User Collaboration</h4>
                            <p className="text-lg text-gray-700">
                                Multiple users can securely access and collaborate on tax returns in real-time, allowing team members to work simultaneously on the same tax data, improving efficiency during the busy tax season.
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaShieldAlt className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Secure Data Protection: At ConciseNext, </h4>
                            <p className="text-lg text-gray-700">
                                we prioritize security. Our hosting platform is equipped with advanced security features like data encryption, firewalls, multi-factor authentication, and SOC-certified data centers to protect your sensitive tax data
                            </p>
                        </div>
                    </li>
                    <li className="flex flex-col md:flex-row items-start md:space-x-4">
                        <div className="flex-shrink-0 mb-4 md:mb-0">
                            <FaDollarSign className="w-8 h-8 text-purple-500" />
                        </div>
                        <div>
                            <h4 className="text-xl font-medium text-gray-800">Cost-Effective Hosting</h4>
                            <p className="text-lg text-gray-700">
                                Save on infrastructure costs by eliminating the need for on-premise servers. Our cloud hosting service provides flexible, scalable resources that you can adjust based on your workload.
                            </p>
                        </div>
                    </li>

                </ul>
            </motion.div>
        );
    };

    const TaxBenefits = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;

        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-7xl mx-auto pt-8 p-6 space-y-8"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800">
                    Key Benefits of UltraTax CS Hosting with ConciseNext
                </h2>

                {/* License Options */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20">
                    {[
                        {
                            title: '99.9% Uptime Guarantee',
                            description:
                                'Ensure that your UltraTax CS software is available whenever you need it. We provide a reliable hosting infrastructure with minimal downtime, so you can maintain productivity even during peak tax season.',
                            imageUrl:
                                'https://imageio.forbes.com/specials-images/imageserve/64c28d7071424270e316a7fe/Data-protection-privacy-concept--Business-man-protecting-his-personal-data-/960x0.jpg?format=jpg&width=960',
                            link: '/quickbooks-pro-hosting',
                        },
                        {
                            title: 'Fully Managed Cloud Hosting',
                            description:
                                'Our fully managed service takes care of server maintenance, software updates, security patches, and backups, allowing you to focus entirely on tax preparation and client services.',
                            imageUrl:
                                'https://www.executivegrapevine.com/uploads/articles/hr-learn20-gpstrategies-thumb.jpg',
                            link: '#',
                        },

                        {
                            title: '24/7 Expert Support',
                            description:
                                'Our experienced support team is available around the clock to assist with any technical issues or questions, ensuring uninterrupted access to your hosted UltraTax CS environment.',
                            imageUrl:
                                'https://www.voipreview.org/sites/voipreview.org/files/styles/blog_post/public/blog/featuredt_images/2019/03/best-online-tech-support-services.jpg?itok=bkbqdkFd',
                            link: '#',
                        },
                        {
                            title: 'Quick and Seamless Migration',
                            description:
                                'Moving your UltraTax CS software and data to our cloud platform is fast and secure. Our migration experts handle the entire process, minimizing downtime and ensuring a smooth transition.',
                            imageUrl:
                                'https://www.gep.com/prod/s3fs-public/blog-images/technology-driven-workplace-transformation-and-how-to-go-about-it.jpg',
                            link: '#',
                        },
                    ].map((license, index) => (
                        <motion.div
                            key={index}
                            whileHover={{ scale: 1.05 }}
                            className="bg-white rounded-lg shadow-lg p-5 flex flex-col items-center text-start space-y-4 transition-transform duration-300"
                        >
                            <img
                                src={license.imageUrl}
                                alt={license.title}
                                className="rounded-lg shadow-lg w-full h-48 object-cover"
                            />
                            <h4 className="text-xl font-semibold text-gray-800">
                                {license.title}
                            </h4>
                            <p className="text-gray-600 pb-10">{license.description}</p>

                        </motion.div>
                    ))}
                </div>
            </motion.div>
        );
    };


    const TaxHostingSolutions = () => {
        const controls = useAnimation();
        const { ref, inView } = useInView({ threshold: 0.2 });

        React.useEffect(() => {
            if (inView) {
                controls.start('visible');
            } else {
                controls.start('hidden');
            }
        }, [controls, inView]);

        const fadeInVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
        };
        const isLargeScreen = window.innerWidth >= 768;

        return (
            <div className="bg-gray-100 text-gray-800 py-16">
                <div className="container mx-auto px-4">
                    <h2 className="text-4xl font-bold text-center mb-12">Drake Tax Software Hosting Plans</h2>

                    <div ref={ref} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-blue-500"
                            initial="hidden"
                            animate={isLargeScreen ? controls : 'visible'}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaCloud className="text-blue-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Single-User Drake Tax Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Ideal for individual tax professionals looking to securely access Drake Tax Software remotely and efficiently.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 hover:bg-red-700 hover:text-white transition-colors rounded-md">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-green-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaServer className="text-green-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Multi-User Drake Tax Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Designed for small to mid-sized tax firms, enabling multiple users to collaborate on tax filings in real-time.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-md hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>

                        <motion.div
                            className="p-8 rounded-lg shadow-lg bg-white border-t-4 border-red-500"
                            initial="hidden"
                            animate={controls}
                            variants={fadeInVariants}
                        >
                            <div className="flex items-center mb-4">
                                <FaDatabase className="text-red-500 text-4xl mr-4" />
                                <h3 className="text-3xl font-semibold">Enterprise-Level Drake Hosting</h3>
                            </div>
                            <p className="text-lg mb-6">
                                Tailored for large CPA firms, offering robust security, scalability, and performance to handle a high volume of tax returns and users.
                            </p>
                            <button className="flex items-center text-white  bg-button border-red-500 px-10 py-3 rounded-lg hover:bg-red-500 hover:text-white transition-colors">
                                Know More
                            </button>
                        </motion.div>
                    </div>
                </div>
            </div>
        );
    };



    const TaxHostingWorks = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

        useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        const isLargeScreen = window.innerWidth >= 768;
        return (
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isLargeScreen ? controls : 'visible'}
                variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.7, ease: 'easeOut' }}
                className="max-w-6xl mx-auto p-8 pt-8 space-y-12 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-lg"
            >
                <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
                    How UltraTax CS Hosting Works
                </h2>

                <div className="relative">
                    <div className="absolute left-1/2 top-0 transform -translate-x-1/2 h-full border-l-2 border-dashed border-gray-300" />
                    <div className="space-y-8">
                        {[
                            {
                                step: 'Consultation',
                                description: 'Our team will assess your tax software needs and recommend the most suitable hosting plan for UltraTax CS.',
                                icon: <FaComments className="w-10 h-10 text-blue-500" />,
                            },
                            {
                                step: 'Secure Migration',
                                description: 'We’ll migrate your UltraTax CS software and all associated data to our cloud platform, ensuring a secure and seamless process with minimal disruption.',
                                icon: <FaExchangeAlt className="w-10 h-10 text-green-500" />,
                            },
                            {
                                step: 'Cloud Access',
                                description: 'Once your software is hosted, you can access UltraTax CS from any device, giving you the freedom to work from anywhere.',
                                icon: <FaCloud className="w-10 h-10 text-purple-500" />,
                            },
                            {
                                step: 'Ongoing Management',
                                description: 'We manage all technical aspects of the hosting environment, including server maintenance, data backups, and security updates, so your system runs smoothly at all times.',

                                icon: <FaHeadset className="w-10 h-10 text-red-500" />,
                            },
                        ].map((step, index) => (
                            <div key={index} className="relative bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center space-y-4">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 flex items-center justify-center bg-white rounded-full border-4 border-gray-200">
                                    {step.icon}
                                </div>
                                <div className="mt-12">
                                    <h3 className="text-2xl font-semibold text-gray-800">{step.step}</h3>
                                    <p className="text-lg text-gray-600 mt-2">{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </motion.div>
        );
    };


    const WhyChooseConciseNext = () => {
        const controls = useAnimation();
        const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

        useEffect(() => {
            if (inView) {
                controls.start("visible");
            }
        }, [controls, inView]);

        const cardVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    duration: 0.6,
                    ease: "easeOut",
                    when: "beforeChildren",
                    staggerChildren: 0.3,
                },
            },
        };

        const iconVariants = {
            hidden: { scale: 0 },
            visible: {
                scale: 1,
                transition: {
                    duration: 0.5,
                    ease: "backOut",
                },
            },
        };

        return (
            <div className="bg-gray-50 py-5">
                <div className="max-w-7xl mx-auto px-6 sm:px-8">
                    <motion.h2
                        className="text-4xl font-bold text-center text-gray-800 mb-12"
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Why Tax Professionals Trust ConciseNext
                    </motion.h2>

                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={cardVariants}
                        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8"
                    >
                        {[
                            {
                                title: "Tax Software Hosting Expertise",
                                description:
                                    "With extensive experience in cloud hosting, ConciseNext delivers tailored solutions for hosting UltraTax CS and other tax software, helping tax professionals and firms enhance their efficiency.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-blue-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaShieldAlt size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Flexible and Scalable Hosting Plans",
                                description:
                                    "Our hosting services are scalable to meet the needs of individual tax preparers and large CPA firms alike. Easily scale up resources as your team or client base grows.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-green-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaLayerGroup size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Robust Data Security",
                                description:
                                    "We implement the latest in security technologies, including multi-layered firewalls, encryption, and secure data centers, to safeguard your sensitive tax data.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaTools size={48} />
                                    </motion.div>
                                ),
                            },
                            {
                                title: "Focus on Tax Compliance",
                                description:
                                    "ConciseNext understands the critical importance of tax compliance and ensures that your hosted UltraTax CS environment meets all regulatory standards and best practices.",
                                icon: (
                                    <motion.div
                                        className="w-12 h-12 text-purple-600 mb-4"
                                        variants={iconVariants}
                                    >
                                        <FaTools size={48} />
                                    </motion.div>
                                ),
                            },
                        ].map((feature, index) => (
                            <motion.div
                                key={index}
                                className="bg-white p-8 rounded-lg shadow-lg text-start flex flex-col items-center"
                            >
                                <div className="flex-shrink-0">{feature.icon}</div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>
            </div>
        );
    };




    const TaxSoftwareVersion = () => {
        const containerVariants = {
            hidden: { opacity: 0, y: 20 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    staggerChildren: 0.3,
                    duration: 0.5,
                    ease: 'easeInOut',
                },
            },
        };

        const itemVariants = {
            hidden: { opacity: 0, y: 10 },
            visible: { opacity: 1, y: 0 },
        };

        const softwareList = [
            { name: 'Drake Tax Software', icon: FaDatabase },
            { name: 'Lacerte Tax Software', icon: FaCloud },
            { name: 'ProSeries Tax Software', icon: FaLock },
            { name: 'UltraTax CS', icon: FaTools },
            { name: 'ProSystem fx', icon: FaCloud },
        ];

        return (
            <div className="relative bg-blue-50 py-16 px-6">
                {/* Background Image */}
                <div className="absolute inset-0 bg-[url('https://cdn.pixabay.com/photo/2024/05/31/06/48/ai-generated-8799924_1280.jpg')] bg-cover bg-center opacity-20"></div>

                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                    className="relative max-w-6xl mx-auto text-center bg-white p-8 rounded-lg shadow-lg"
                >
                    {/* Title Section */}
                    <motion.h2
                        variants={itemVariants}
                        className="text-4xl font-bold text-blue-900 mb-8"
                    >
                        Supported Tax Software
                    </motion.h2>

                    {/* List of Tax Software with Icons */}
                    <motion.ul variants={containerVariants} className="space-y-4 text-left">
                        {softwareList.map((software, index) => (
                            <motion.li
                                key={index}
                                variants={itemVariants}
                                className="flex items-center text-lg text-blue-800 font-medium"
                            >
                                <software.icon className="text-blue-600 w-8 h-8 mr-4" />
                                {software.name}
                            </motion.li>
                        ))}
                    </motion.ul>

                    {/* Custom Hosting Solutions Section */}
                    <motion.p
                        variants={itemVariants}
                        className="mt-8 text-blue-700 text-lg"
                    >
                        If you need custom hosting solutions for any other tax software,{' '}
                        <span className="font-semibold underline hover:text-blue-900 cursor-pointer">
                            contact us
                        </span>{' '}
                        for personalized support.
                    </motion.p>

                    {/* Divider */}
                    <div className="mt-12">
                        <hr className="border-blue-300 mb-8" />

                        {/* TaxWise Hosting Section with Image */}
                        <motion.div variants={containerVariants} className="text-left">
                            <motion.h3
                                variants={itemVariants}
                                className="text-3xl font-bold text-blue-900 mb-4"
                            >
                                We can also accommodate other tax software hosting needs—contact us for more information on custom solutions
                            </motion.h3>
                            <div className="flex flex-col md:flex-row md:items-center">
                                <motion.p
                                    variants={itemVariants}
                                    className="text-lg text-blue-700 leading-relaxed md:w-1/2"
                                >
                                    Streamline your tax preparation process with ConciseNext’s UltraTax CS Hosting. Enjoy the freedom to work from anywhere, enhanced collaboration, and the peace of mind that your tax software is hosted in a secure, reliable, and managed cloud environment.
                                </motion.p>
                                {/* Image with transition */}
                                <motion.img
                                    src="https://cdn.pixabay.com/photo/2024/05/31/06/48/ai-generated-8799924_1280.jpg"
                                    alt="TaxWise Hosting"
                                    className="rounded-lg shadow-lg w-full md:w-1/2 mt-6 md:mt-0 md:ml-8"
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5 }}
                                />
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        );
    };


    const ContactUs = () => {
        return (
            <section className="relative bg-gray-100 py-6 px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="absolute inset-0">
                    <svg className="absolute top-0 right-0 transform translate-x-1/2 translate-y-1/4 -z-10" width="404" height="404" fill="none" viewBox="0 0 404 404">
                        <defs>
                            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-indigo-600" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="100%" height="100%" fill="url(#pattern1)" />
                    </svg>
                </div>
                <div className="relative max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <motion.h2
                        className="text-3xl font-extrabold text-gray-900 mb-6"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                    >
                        Contact Us
                    </motion.h2>
                    <div className="flex flex-col items-center">
                        <motion.div
                            className="text-gray-800 mb-4"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
                        >
                            <p className="text-lg font-semibold">📞 Call us: +1 (800) 123-4567</p>
                            <p className="text-lg font-semibold">📧 Email us: <a href="mailto:sales@ConciseNext.com" className="text-indigo-600 hover:underline">sales@ConciseNext.com</a></p>
                        </motion.div>
                        <motion.p
                            className="text-gray-500"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
                        >
                            ConciseNext – The trusted cloud hosting solution for your UltraTax CS software.
                        </motion.p>
                    </div>
                </div>
            </section>
        );
    };




    return (
        <div>
            <TaxHero />
            <TaxIntroSection />
            <TaxBenefits />
            <TaxHostingSolutions />
            <TaxHostingWorks />
            <WhyChooseConciseNext />
            <TaxSoftwareVersion />
            <ContactUs />

           
        </div>
    )

}